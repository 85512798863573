import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PrivacyPolicyModalView from './privacy-policy-modal-view';
import { richText } from '../../models/cms';

class PrivacyPolicyModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    acceptBtnClick: PropTypes.func,
    termsAndConditions: richText,
    privacyPolicy: richText,
  };

  render() {
    const {
      isModalOpen,
      closeModal,
      acceptBtnClick,
      termsAndConditions,
      privacyPolicy,
    } = this.props;

    return (
      <PrivacyPolicyModalView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        acceptBtnClick={acceptBtnClick}
        termsAndConditions={termsAndConditions}
        privacyPolicy={privacyPolicy}
      />
    );
  }
}

export default PrivacyPolicyModal;
