import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';

import { richText, iconText, img } from '../../models/cms';

export default class ShowcaseView extends Component {
  static propTypes = {
    title: richText,
    advantages: iconText,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        picture: img,
      })
    ),
  };

  render() {
    const { title, advantages, pictures } = this.props;

    return (
      <Container className="px-sm-5">
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6}>
            <div className="showcase-left ml-md-auto">
              <div className="pb-sm-5 text-center text-md-left">
                {title && RichText.render(title)}
              </div>
              {advantages &&
                advantages.map((x, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <div
                    key={index}
                    className="py-4 mr-lg-3 advantages-item-container"
                  >
                    <img
                      src={x.icon.url}
                      alt={x.icon.alt}
                      className="d-inline mr-3 mr-lg-5"
                    />
                    <div className="d-inline-block font-weight-normal">
                      {RichText.render(x.text)}
                    </div>
                  </div>
                  /* eslint-enable react/no-array-index-key */
                ))}
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-right">
            {pictures &&
              pictures.map((x, index) => (
                /* eslint-disable react/no-array-index-key */
                <img
                  key={index}
                  src={x.picture.url}
                  alt={x.picture.alt}
                  className="my-3 advantages-picture"
                />
                /* eslint-enable react/no-array-index-key */
              ))}
          </Col>
        </Row>
      </Container>
    );
  }
}
