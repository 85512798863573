import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

export default class RestartOrderModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    restartOrder: PropTypes.func,
  };

  render() {
    const { isModalOpen, closeModal, restartOrder } = this.props;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="text-center px-5 pb-5">
          <div className="mb-4">
            <h3 className="mb-3">
              <strong>{i18n.t('restartOrderModal.title')}</strong>
            </h3>
            <p>{i18n.t('restartOrderModal.description')}</p>
          </div>
          <Button
            variant="outline-primary"
            onClick={closeModal}
            className="mt-1 mx-1"
          >
            {i18n.t('restartOrderModal.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={restartOrder}
            className="mt-1 mx-1"
          >
            {i18n.t('restartOrderModal.ok')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}
