import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import './styles/reset.scss';
import './styles/base.scss';
import 'react-toastify/dist/ReactToastify.css';

import configureStore from './store';
import Main from './view/main';

const { store, persistor } = configureStore();
const history = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Main />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
export { history };
