import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import ThankYouModal from './thank-you';

export default class ContactModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    handleInputChange: PropTypes.func,
    isNameInvalid: PropTypes.bool,
    isEmailInvalid: PropTypes.bool,
    isPhoneInvalid: PropTypes.bool,
    isMessageInvalid: PropTypes.bool,
    submitUserdata: PropTypes.func,
    isThankYouModalOpen: PropTypes.bool,
  };

  render() {
    const {
      isModalOpen,
      closeModal,
      handleInputChange,
      submitUserdata,
      isNameInvalid,
      isEmailInvalid,
      isPhoneInvalid,
      isMessageInvalid,
      isThankYouModalOpen,
    } = this.props;

    return (
      <>
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton />
          <Modal.Body className="px-2 px-md-5 pb-5">
            <div className="text-center">
              <h2 className="mb-5">{i18n.t('contactModal.title')}</h2>
            </div>
            <Form className="mx-md-5">
              <Form.Group controlId="contactName">
                <Form.Label>{i18n.t('contactModal.name.label')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t('contactModal.name.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isNameInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {i18n.t('contactModal.name.invalidMsg')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="contactPhone">
                <Form.Label>{i18n.t('contactModal.phone.label')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t('contactModal.phone.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isPhoneInvalid}
                />
              </Form.Group>

              <Form.Group controlId="contactEmail">
                <Form.Label>{i18n.t('contactModal.email.label')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={i18n.t('contactModal.email.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isEmailInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {i18n.t('contactModal.email.invalidMsg')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="contactComment">
                <Form.Label>{i18n.t('contactModal.message.label')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={i18n.t('contactModal.message.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isMessageInvalid}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="mt-2 w-100"
                onClick={submitUserdata}
              >
                {i18n.t('contactModal.submitBtn')}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <ThankYouModal isModalOpen={isThankYouModalOpen} />
      </>
    );
  }
}
