import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { richText, iconText, img } from '../../models/cms';
import ShowcaseView from './showcase-view';

class Showcase extends Component {
  static propTypes = {
    title: richText,
    advantages: iconText,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        picture: img,
      })
    ),
  };

  render() {
    const { title, advantages, pictures } = this.props;

    return (
      <ShowcaseView title={title} advantages={advantages} pictures={pictures} />
    );
  }
}

export default Showcase;
