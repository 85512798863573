/* globals fetch */
export const ResponseTypes = {
  json: 'json',
  blob: 'blob',
};

export default class ApiHandler {
  static getData(text, status) {
    let data;
    if (!text) {
      return {};
    }

    try {
      data = JSON.parse(text);
    } catch {
      // HTML error page returned
      data = {
        status: 'fail',
        message: `(${status !== 200 ? status : 500}) Server error`,
      };
    }

    return data;
  }

  static async fetch(url, config, responseType = ResponseTypes.json) {
    const res = await fetch(url, config);

    if (res && res.ok) {
      switch (responseType) {
        case ResponseTypes.blob:
          return res.blob().then((blob) => ({
            ok: !!res.ok,
            status: res.status,
            headers: res.headers ? res.headers.map : {},
            data: blob,
          }));
        default:
          return res.text().then((text) => {
            const data = ApiHandler.getData(text, res.status);
            return {
              ok: !!res.ok && data.status !== 'fail',
              status: data.status !== 'fail' ? res.status : 500,
              headers: res.headers ? res.headers.map : {},
              data,
            };
          });
      }
    }

    // request failed
    return (
      (res &&
        res.text().then((text) => ({
          ok: !!res.ok,
          status: res.status,
          headers: res.headers ? res.headers.map : {},
          data: ApiHandler.getData(text, res.status),
        }))) ||
      {}
    );
  }
}
