import React, { Component } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { motor, cartItems } from '../../../../models/products';
import i18n from '../../../../i18n';
import Image from '../../../image';

export default class MotorModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    data: motor,
    cart: cartItems,
  };

  render() {
    const { isModalOpen, closeModal, data, cart } = this.props;

    if (!data) return null;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="px-5 pb-5">
          <Row>
            <Col xs={4}>
              <Image
                className="mw-100"
                imageName={data.picture_file_name}
                alt="motor"
                fallbackImg="empty_image_big.png"
              />
            </Col>
            <Col xs={8}>
              <h2 className="mb-3">{data.desc}</h2>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.motorType')}{' '}
                </strong>{' '}
                {data.motor_type}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.boatType')}{' '}
                </strong>
                {data.boat_type}
              </p>
              <p>
                <strong>
                  {i18n.t(
                    'priceRequest.productModals.motor.maxSuggestedWeight'
                  )}{' '}
                </strong>
                {cart.boatType === 'sailing'
                  ? data.max_suggested_boat_weight_sail
                  : data.max_suggested_boat_weight_motor}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.brand')}{' '}
                </strong>
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.voltage')}{' '}
                </strong>{' '}
                {data.voltage && `${data.voltage} V`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.continousCurrent')}{' '}
                </strong>{' '}
                {data.continous_current && `${data.continous_current} kW`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.boostMaximal')}{' '}
                </strong>{' '}
                {!!data.max_current && `${data.max_current} kW`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.motor.price')}{' '}
                </strong>
                {data.net_price &&
                  `${String(data.net_price).replace(
                    /(.)(?=(\d{3})+$)/g,
                    '$1 '
                  )} HUF`}
              </p>
            </Col>
          </Row>
          <p>
            <strong>
              {i18n.t('priceRequest.productModals.motor.description')}{' '}
            </strong>
          </p>
          <p>{data.desc}</p>
          <Col xs={12} className="text-center">
            <Button
              variant="primary"
              onClick={closeModal}
              className="mt-1 mx-1"
            >
              {i18n.t('priceRequest.productModals.motor.close')}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    );
  }
}
