import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import BatteryTable from './battery-table/battery-table';
import { batteries as batteriesModel } from '../../../models/products';
import ListPrice from '../list-price';

export default function BatteryView(props) {
  const {
    batteries,
    selectedBattery,
    addBattery,
    removeBattery,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;

  return (
    <>
      <h3 className="mx-15">{i18n.t('priceRequest.titles.battery')}</h3>
      <BatteryTable
        batteries={batteries}
        addBattery={addBattery}
        removeBattery={removeBattery}
        selectedBattery={selectedBattery}
        onSelectClick={onSelectClick}
      />
      <ListPrice
        nextButtonRef={bottomSectionRef}
        setStep={setStep}
        activeStep={activeStep}
      />
    </>
  );
}

BatteryView.propTypes = {
  batteries: batteriesModel,
  selectedBattery: PropTypes.objectOf(PropTypes.any),
  addBattery: PropTypes.func,
  removeBattery: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
