import React, { Component } from 'react';

import { richText, iconText } from '../../models/cms';
import JumbotronView from './jumbotron-view';

class Jumbotron extends Component {
  static propTypes = {
    title: richText,
    desc: richText,
    steps: iconText,
  };

  render() {
    const { title, desc, steps } = this.props;

    return <JumbotronView title={title} desc={desc} steps={steps} />;
  }
}

export default Jumbotron;
