import React, { Component } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { charger } from '../../../../models/products';
import i18n from '../../../../i18n';
import Image from '../../../image';

export default class ChargerModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    data: charger,
  };

  render() {
    const { isModalOpen, closeModal, data } = this.props;

    if (!data) return null;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="px-5 pb-5">
          <Row>
            <Col xs={4}>
              <Image
                className="mw-100"
                imageName={data.picture_file_name}
                alt="charger"
                fallbackImg="empty_image_big.png"
              />
            </Col>
            <Col xs={8}>
              <h2 className="mb-3">{data.description}</h2>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.charger.chargeCurrent')}{' '}
                </strong>
                {data.charge_current && `${data.charge_current} A`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.charger.timeToCharge')}{' '}
                </strong>
                {data.time_to_charge &&
                  `${data.time_to_charge} ${i18n.t(
                    'priceRequest.productModals.hours'
                  )}`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.charger.voltage')}{' '}
                </strong>{' '}
                {data.voltage && `${data.voltage} V`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.charger.price')}{' '}
                </strong>
                {data.price_charger &&
                  `${String(data.price_charger).replace(
                    /(.)(?=(\d{3})+$)/g,
                    '$1 '
                  )} HUF`}
              </p>
            </Col>
          </Row>
          <p>
            <strong>
              {i18n.t('priceRequest.productModals.charger.description')}
            </strong>
          </p>
          <p>{data.description}</p>
          <Col xs={12} className="text-center">
            <Button
              variant="primary"
              onClick={closeModal}
              className="mt-1 mx-1"
            >
              {i18n.t('priceRequest.productModals.charger.close')}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    );
  }
}
