import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import MotorView from './motor-view';
import {
  motors as motorsModel,
  cartItems as cartModel,
} from '../../../models/products';

const filter = (motors, cart, motorSubtypes) => {
  const { boatType, motorType, boatWeight } = cart;
  let filteredMotors;

  // Boat type filter
  filteredMotors = motors.filter((motor) => {
    return motor.boat_type === boatType || motor.boat_type === null;
  });

  // Motor type filter
  if (motorType === 'inner') {
    filteredMotors = filteredMotors.filter(
      (motor) =>
        motor.category.toLowerCase() !== 'outboard' ||
        motor.category.toLowerCase() !== 'külmotor'
    );
  } else {
    filteredMotors = filteredMotors.filter(
      (motor) =>
        motor.category.toLowerCase() === 'outboard' ||
        motor.category.toLowerCase() === 'külmotor'
    );
  }

  // Boat weight filter
  if (boatType === 'sailing') {
    filteredMotors = filteredMotors.filter(
      (motor) => motor.max_suggested_boat_weight_sail >= boatWeight
    );
  } else {
    filteredMotors = filteredMotors.filter(
      (motor) => motor.max_suggested_boat_weight_motor >= boatWeight
    );
  }

  // Motor subtype filter
  filteredMotors = filteredMotors.filter((motor) =>
    motorSubtypes.some((subtype) => motor.motor_type.includes(subtype))
  );

  return filteredMotors;
};

export default function MotorSelect(props) {
  const selectedMotor = useSelector((state) => state.cart.motor);
  const {
    addMotor,
    removeMotor,
    cart,
    products,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;
  const { motors } = products;

  const allSubtypes = motors.reduce(
    (types, motor) => {
      if (motor.category === 'outboard' || motor.category === 'külmotor') {
        if (!types.outer.includes(motor.motor_type)) {
          types.outer.push(motor.motor_type);
        }
      } else if (!types.inner.includes(motor.motor_type)) {
        types.inner.push(motor.motor_type);
      }
      return types;
    },
    { inner: [], outer: [] }
  );

  const subtypes = allSubtypes[cart.motorType];

  const [filterOptions, setFilterOptions] = useState(subtypes);

  // Filter all motors based on boat type, motor type, boat weight
  const [filteredMotors, setFilteredMotors] = React.useState(
    filter(motors, cart, filterOptions)
  );

  useEffect(() => {
    setFilteredMotors(filter(motors, cart, filterOptions));
  }, [motors, cart, filterOptions]);

  const onFilterClick = (subtype) => {
    const isSelected = filterOptions.includes(subtype);
    if (isSelected && filterOptions.length === 1) return;

    if (isSelected) {
      setFilterOptions(filterOptions.filter((option) => option !== subtype));
    } else {
      setFilterOptions([...filterOptions, subtype]);
    }
  };

  return (
    <MotorView
      motors={filteredMotors}
      onFilterClick={onFilterClick}
      addMotor={addMotor}
      removeMotor={removeMotor}
      selectedMotor={selectedMotor}
      cart={cart}
      setStep={setStep}
      activeStep={activeStep}
      subtypes={subtypes}
      filterOptions={filterOptions}
      onSelectClick={onSelectClick}
      bottomSectionRef={bottomSectionRef}
    />
  );
}

MotorSelect.propTypes = {
  products: PropTypes.shape({
    motors: motorsModel,
  }),
  motors: motorsModel,
  addMotor: PropTypes.func,
  cart: cartModel,
  removeMotor: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.number,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
