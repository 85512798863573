import React from 'react';
import PropTypes from 'prop-types';

export default function PriceField({ price, discountPrice, discountPercent }) {
  const getDiscountPercent = () => {
    if (discountPercent) {
      return `-${discountPercent}%`;
    }

    const percent = 100 - Math.round((discountPrice / price) * 100);
    return `-${percent}%`;
  };

  return (
    <strong className="product-price">
      <span className={!!discountPrice && 'line-through'}>
        {price && `${String(price).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} HUF`}
      </span>
      {!!discountPrice && (
        <span className="discount-percentage">{getDiscountPercent()}</span>
      )}
      {!!discountPrice && (
        <div className="discount-price">
          {String(discountPrice).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} HUF
        </div>
      )}
    </strong>
  );
}

PriceField.propTypes = {
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  discountPercent: PropTypes.number,
};
