import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FooterView from './footer-view';

class Footer extends Component {
  static propTypes = {
    langChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubscribeModalOpen: false,
      isContactModalOpen: false,
    };
    this.openSubscribeModal = this.openSubscribeModal.bind(this);
    this.closeSubscribeModal = this.closeSubscribeModal.bind(this);
    this.openContactModal = this.openContactModal.bind(this);
    this.closeContactModal = this.closeContactModal.bind(this);
  }

  openSubscribeModal() {
    this.setState({ isSubscribeModalOpen: true });
  }

  closeSubscribeModal() {
    this.setState({ isSubscribeModalOpen: false });
  }

  openContactModal() {
    this.setState({ isContactModalOpen: true });
  }

  closeContactModal() {
    this.setState({ isContactModalOpen: false });
  }

  render() {
    const {
      state,
      openSubscribeModal,
      closeSubscribeModal,
      openContactModal,
      closeContactModal,
      props,
    } = this;

    return (
      <FooterView
        isSubscribeModalOpen={state.isSubscribeModalOpen}
        openSubscribeModal={openSubscribeModal}
        closeSubscribeModal={closeSubscribeModal}
        isContactModalOpen={state.isContactModalOpen}
        openContactModal={openContactModal}
        closeContactModal={closeContactModal}
        langChange={props.langChange}
      />
    );
  }
}

export default Footer;
