import PropTypes from 'prop-types';

export const richText = PropTypes.arrayOf(
  PropTypes.shape({
    spans: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
        type: PropTypes.string,
      })
    ),
    text: PropTypes.string,
    type: PropTypes.string,
  })
);

export const img = PropTypes.shape({
  alt: PropTypes.string,
  copyright: PropTypes.string,
  url: PropTypes.string,
  dimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
});

export const iconText = PropTypes.arrayOf(
  PropTypes.shape({
    icon: PropTypes.shape({
      alt: PropTypes.string,
      copyright: PropTypes.string,
      url: PropTypes.string,
      dimensions: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
    text: PropTypes.arrayOf(
      PropTypes.shape({
        spans: PropTypes.arrayOf(
          PropTypes.shape({
            end: PropTypes.number,
            start: PropTypes.number,
            type: PropTypes.string,
          })
        ),
        text: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  })
);

export const checkList = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.arrayOf(
      PropTypes.shape({
        spans: PropTypes.arrayOf(
          PropTypes.shape({
            end: PropTypes.number,
            start: PropTypes.number,
            type: PropTypes.string,
          })
        ),
        text: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  })
);
