import { connect } from 'react-redux';

import ContactModal from './contact-modal';

import { sendUserdata } from '../../reducers/request';

const mapStateToProps = ({ userdata }) => {
  return {
    isThankYouModalOpen: userdata && userdata.isModalOpen,
  };
};

export default connect(mapStateToProps, { sendUserdata })(ContactModal);
