import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { richText, img, iconText } from '../../models/cms';
import FloatingButton from '../../components/floating-button';
import Navigation from '../../components/navigation';
import Slideshow from '../../components/slideshow';
import About from '../../components/about';
import Showcase from '../../components/showcase';
import Jumbotron from '../../components/jumbotron';
import Checks from '../../components/checks';
import Stepper from '../../components/stepper';
import Footer from '../../components/footer';

class MainView extends Component {
  static propTypes = {
    data: PropTypes.shape({
      about_description: richText,
      about_golden_description: richText,
      about_title: richText,
      checks_battery: PropTypes.arrayOf(
        PropTypes.shape({
          text: richText,
        })
      ),
      checks_image: img,
      checks_motor: PropTypes.arrayOf(
        PropTypes.shape({
          text: richText,
        })
      ),
      jumbotron_description: richText,
      jumbotron_steps: iconText,
      jumbotron_title: richText,
      privacy_policy: richText,
      showcase_advantages: iconText,
      showcase_pictures: PropTypes.arrayOf(
        PropTypes.shape({
          picture: img,
        })
      ),
      showcase_title: richText,
      slideshow_background: img,
      slideshow_background_mobile: img,
      slideshow_title: richText,
      terms_and_conditions: richText,
      ty_description: richText,
      ty_title: richText,
    }),
  };

  render() {
    const { data } = this.props;

    if (data) {
      return (
        <>
          <FloatingButton />
          <Navigation />
          <Slideshow
            title={data.slideshow_title}
            bg={{
              desktop: data.slideshow_background,
              mobile: data.slideshow_background_mobile,
            }}
          />
          <About
            title={data.about_title}
            goldenDesc={data.about_golden_description}
            desc={data.about_description}
          />
          <Showcase
            title={data.showcase_title}
            advantages={data.showcase_advantages}
            pictures={data.showcase_pictures}
          />
          <Jumbotron
            title={data.jumbotron_title}
            desc={data.jumbotron_description}
            steps={data.jumbotron_steps}
          />
          <Checks
            image={data.checks_image}
            motor={data.checks_motor}
            battery={data.checks_battery}
          />
          <Stepper
            privacyPolicy={data.privacy_policy}
            termsAndConditions={data.terms_and_conditions}
            tyTitle={data.ty_title}
            tyDesc={data.ty_description}
          />
          <Footer />
        </>
      );
    }

    return null;
  }
}

export default MainView;
