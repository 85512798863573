import React, { Component } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { battery } from '../../../../models/products';
import i18n from '../../../../i18n';
import Image from '../../../image';

export default class BatteryModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    data: battery,
  };

  render() {
    const { isModalOpen, closeModal, data } = this.props;

    if (!data) return null;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="px-5 pb-5">
          <Row>
            <Col xs={4}>
              <Image
                className="mw-100"
                imageName={data.picture_file_name}
                alt="battery"
                fallbackImg="empty_image_big.png"
              />
            </Col>
            <Col xs={8}>
              <h2 className="mb-3">{data.description}</h2>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.battery.totalCapacity')}{' '}
                </strong>
                {data.total_capacity &&
                  `${String(data.total_capacity).replace(
                    /(.)(?=(\d{3})+$)/g,
                    '$1 '
                  )} Wh`}
              </p>
              <p>
                <strong>
                  {i18n.t(
                    'priceRequest.productModals.battery.approxRunningTime'
                  )}{' '}
                </strong>{' '}
                {data.approx_running_time &&
                  `${data.approx_running_time} ${i18n.t(
                    'priceRequest.productModals.hours'
                  )}`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.battery.pieces')}{' '}
                </strong>
                {data.pieces}
              </p>
              <p>
                <strong>
                  {i18n.t(
                    'priceRequest.productModals.battery.capacityPerPiece'
                  )}{' '}
                </strong>
                {data.capacity_per_piece &&
                  `${String(data.capacity_per_piece).replace(
                    /(.)(?=(\d{3})+$)/g,
                    '$1 '
                  )} Wh`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.battery.piecesParallel')}{' '}
                </strong>
                {data.pieces_parallel}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.battery.voltage')}{' '}
                </strong>{' '}
                {data.system_voltage && `${data.system_voltage} V`}
              </p>
              <p>
                <strong>
                  {i18n.t('priceRequest.productModals.battery.price')}{' '}
                </strong>
                {data.total_price &&
                  `${String(data.total_price).replace(
                    /(.)(?=(\d{3})+$)/g,
                    '$1 '
                  )} HUF`}
              </p>
            </Col>
          </Row>
          <p>
            <strong>
              {i18n.t('priceRequest.productModals.battery.description')}
            </strong>
          </p>
          <p>{data.description}</p>
          <Col xs={12} className="text-center">
            <Button
              variant="primary"
              onClick={closeModal}
              className="mt-1 mx-1"
            >
              {i18n.t('priceRequest.productModals.battery.close')}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    );
  }
}
