import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import ThankYouModal from './thank-you';

export default class SubscribeModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    handleInputChange: PropTypes.func,
    isEmailInvalid: PropTypes.bool,
    submitUserdata: PropTypes.func,
    isThankYouModalOpen: PropTypes.bool,
    closeThankYouModal: PropTypes.func,
  };

  render() {
    const {
      isModalOpen,
      isEmailInvalid,
      handleInputChange,
      submitUserdata,
      closeModal,
      isThankYouModalOpen,
      closeThankYouModal,
    } = this.props;

    return (
      <>
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton />
          <Modal.Body className="text-center px-2 px-md-5 pb-5">
            <div className="mb-4">
              <h2>{i18n.t('subscribeModal.title')}</h2>
              <p>{i18n.t('subscribeModal.description')}</p>
            </div>
            <Form className="mx-md-5">
              <Form.Group controlId="subscribeEmail">
                <Form.Control
                  type="email"
                  placeholder={i18n.t('contactModal.email.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isEmailInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {i18n.t('contactModal.email.invalidMsg')}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="primary"
                onClick={submitUserdata}
                className="w-100"
              >
                {i18n.t('subscribeModal.subscribeButton')}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <ThankYouModal
          isModalOpen={isThankYouModalOpen}
          closeModal={closeThankYouModal}
        />
      </>
    );
  }
}
