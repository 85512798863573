import React, { Component } from 'react';

import NavigationView from './navigation-view';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isModalOpen: false,
    };
    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  toggleNavigation() {
    const { isExpanded } = this.state;

    this.setState({ isExpanded: !isExpanded });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isExpanded, isModalOpen } = this.state;
    const { toggleNavigation, openModal, closeModal } = this;

    return (
      <NavigationView
        isExpanded={isExpanded}
        isModalOpen={isModalOpen}
        toggleNavigation={toggleNavigation}
        openModal={openModal}
        closeModal={closeModal}
      />
    );
  }
}

export default Navigation;
