import ApiHandlerService from '../../services/api-handler';
import appConfig from '../../config';

export default class SubscribeApi {
  static subscribe(variables, token) {
    const userdata = variables && variables.user_data;

    const url = appConfig.SUBSCRIBE_ENDPOINT;

    const params = {};

    if (userdata) params.user_data = userdata;

    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
      },
      method: 'POST',
    };

    // Use _GET_ https://jsonplaceholder.typicode.com/posts as URL for testing
    return ApiHandlerService.fetch(url, params, config);
  }
}
