import { connect } from 'react-redux';

import PriceRequest from './price-request';
import {
  removeAccessory,
  removeBattery,
  removeCharger,
  removeMotor,
  flushCart,
} from '../../../reducers/cart';
import { sendOffer } from '../../../reducers/request';

const mapStateToProps = ({ offer, cart }) => {
  return {
    isThankYouModalOpen: offer && offer.isModalOpen,
    cart,
  };
};

export default connect(mapStateToProps, {
  removeAccessory,
  removeBattery,
  removeCharger,
  removeMotor,
  flushCart,
  sendOffer,
})(PriceRequest);
