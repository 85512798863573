import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import {
  accessory as accessoryModel,
  battery as batteryModel,
  charger as chargerModel,
  motor as motorModel,
} from '../../../models/products';

function ConditionalButtons(props) {
  const { setStep, activeStep, total, buttonRef } = props;

  switch (activeStep) {
    case 1:
      return (
        <Col ref={buttonRef} xs={12} md={5} className="ml-auto">
          <Row>
            <Col xs={12} sm={6} className="my-1">
              <Button
                variant="outline-dark"
                disabled={total === 0}
                className="w-100"
                onClick={() => setStep(5)}
              >
                {i18n.t('priceRequest.buttons.requestOffer')}
              </Button>
            </Col>
            <Col xs={12} sm={6} className="my-1 select-btn">
              <Button
                variant="primary"
                className="btn-arrow w-100"
                onClick={() => setStep(activeStep + 1)}
              >
                {i18n.t('priceRequest.buttons.selectBattery')}
              </Button>
            </Col>
          </Row>
          <div className="text-primary fs-14 help-text">
            {i18n.t('priceRequest.buttons.needHelp')}
          </div>
        </Col>
      );
    case 2:
      return (
        <Col ref={buttonRef} xs={12} md={5} className="ml-auto">
          <Row>
            <Col xs={12} sm={6} className="my-1">
              <Button
                variant="outline-dark"
                disabled={total === 0}
                className="w-100"
                onClick={() => setStep(5)}
              >
                {i18n.t('priceRequest.buttons.requestOffer')}
              </Button>
            </Col>
            <Col xs={12} sm={6} className="my-1 select-btn">
              <Button
                variant="primary"
                className="btn-arrow w-100"
                onClick={() => setStep(activeStep + 1)}
              >
                {i18n.t('priceRequest.buttons.selectCharger')}
              </Button>
            </Col>
          </Row>
          <div className="text-primary fs-14 help-text">
            {i18n.t('priceRequest.buttons.needHelp')}
          </div>
        </Col>
      );
    case 3:
      return (
        <Col ref={buttonRef} xs={12} md={5} className="ml-auto">
          <Row>
            <Col xs={12} sm={6} className="my-1">
              <Button
                variant="outline-dark"
                disabled={total === 0}
                className="w-100"
                onClick={() => setStep(5)}
              >
                {i18n.t('priceRequest.buttons.requestOffer')}
              </Button>
            </Col>
            <Col xs={12} sm={6} className="my-1 select-btn">
              <Button
                variant="primary"
                className="btn-arrow w-100"
                onClick={() => setStep(activeStep + 1)}
              >
                {i18n.t('priceRequest.buttons.addAccessory')}
              </Button>
            </Col>
          </Row>
          <div className="text-primary fs-14 help-text">
            {i18n.t('priceRequest.buttons.needHelp')}
          </div>
        </Col>
      );
    case 4:
      return (
        <Col
          ref={buttonRef}
          xs={12}
          md={3}
          lg={4}
          className="ml-auto text-right"
        >
          <Button
            variant="primary"
            className="btn-arrow w-100"
            onClick={() => setStep(activeStep + 1)}
          >
            {i18n.t('priceRequest.buttons.orderSummary')}
          </Button>
        </Col>
      );
    default:
      return 'Unknown step';
  }
}

export default function ListPriceView(props) {
  const {
    total,
    motor,
    battery,
    charger,
    accessory,
    totalDiscountedPrice,
    removeAccessory,
    removeCharger,
    removeMotor,
    removeBattery,
    setStep,
    activeStep,
    nextButtonRef,
  } = props;

  let itemCount = 0;

  if (motor.name) itemCount += 1;
  if (battery.name) itemCount += 1;
  if (charger.name) itemCount += 1;
  if (accessory.name) itemCount += 1;

  const list = [
    { ...motor, onRemoveClick: removeMotor, isPlusSignBefore: false },
    {
      ...battery,
      onRemoveClick: removeBattery,
      isPlusSignBefore: !!motor.name,
    },
    {
      ...charger,
      onRemoveClick: removeCharger,
      isPlusSignBefore: !!(motor.name || battery.name),
    },
    {
      ...accessory,
      onRemoveClick: removeAccessory,
      isPlusSignBefore: !!(motor.name || battery.name || charger.name),
    },
  ];

  return (
    <Row className="mx-3 mt-5 list-price">
      <Col
        xs={12}
        md={activeStep < 4 ? 7 : 9}
        lg={activeStep < 4 ? 7 : 8}
        className="container"
      >
        <Row className="justify-content-end">
          {total !== 0 && (
            <Col
              xs={12}
              md={2}
              className={`text-center px-2 title ${
                itemCount === 4 ? 'newline' : ''
              }`}
            >
              <h5>{i18n.t('priceRequest.listPrice.title')}</h5>
            </Col>
          )}

          {list.map(
            (product) =>
              product.name && (
                <Col
                  xs={12}
                  md={2}
                  className={`text-center px-2 ellipsis-overflow ${
                    product.isPlusSignBefore ? 'plus-sign-before' : ''
                  }`}
                >
                  <h5 className="product-price">
                    <span
                      className={!!product.discountedPrice && 'line-through'}
                    >
                      {product.price &&
                        `${String(product.price).replace(
                          /(.)(?=(\d{3})+$)/g,
                          '$1 '
                        )} HUF`}
                    </span>
                    <div className="discount-price">
                      {!!product.discountedPrice &&
                        `${String(product.discountedPrice).replace(
                          /(.)(?=(\d{3})+$)/g,
                          '$1 '
                        )} HUF`}
                    </div>
                  </h5>
                  <p className="mb-0">
                    <div className="text-primary list-price-item-name">
                      {product.name}
                    </div>
                  </p>
                  <Button
                    variant="link"
                    onClick={() => product.onRemoveClick(product.price)}
                    className="d-md-none"
                  >
                    {i18n.t('priceRequest.listPrice.remove')}
                  </Button>
                  <hr className="d-md-none" />
                </Col>
              )
          )}

          {total !== 0 ? (
            <Col
              xs={12}
              md={2}
              className={`text-center px-2 equal-sign-before ${
                itemCount <= 1 ? 'd-none' : ''
              }`}
            >
              <h5 className="product-price">
                <span
                  className={total > totalDiscountedPrice && 'line-through'}
                >
                  {String(total).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} HUF
                </span>
                {total > totalDiscountedPrice && (
                  <div className="discount-price">
                    {String(totalDiscountedPrice).replace(
                      /(.)(?=(\d{3})+$)/g,
                      '$1 '
                    )}{' '}
                    HUF
                  </div>
                )}
              </h5>
              <h6 className="mt-3">{i18n.t('priceRequest.listPrice.total')}</h6>
            </Col>
          ) : null}
        </Row>
        <Row className="justify-content-end d-none d-md-flex">
          <Col xs={0} md={2} />
          {list.map(
            (product) =>
              product.name && (
                <Col xs={12} md={2} className="text-center px-2">
                  <Button
                    variant="link"
                    className="py-0 remove-btn"
                    onClick={() => product.onRemoveClick(product.price)}
                  >
                    {i18n.t('priceRequest.listPrice.remove')}
                  </Button>
                </Col>
              )
          )}

          {itemCount > 1 ? <Col xs={12} md={2} /> : null}
        </Row>
      </Col>
      <ConditionalButtons
        buttonRef={nextButtonRef}
        setStep={setStep}
        activeStep={activeStep}
        total={total}
      />
    </Row>
  );
}

ListPriceView.propTypes = {
  removeCharger: PropTypes.func,
  removeBattery: PropTypes.func,
  removeMotor: PropTypes.func,
  removeAccessory: PropTypes.func,
  total: PropTypes.number,
  totalDiscountedPrice: PropTypes.number,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  nextButtonRef: PropTypes.instanceOf(Object),
  motor: motorModel,
  battery: batteryModel,
  charger: chargerModel,
  accessory: accessoryModel,
};
