import { all, call } from 'redux-saga/effects';

import notificationSaga from './notification';
import requestSaga from './request';

function* rootSaga() {
  yield all([call(notificationSaga), call(requestSaga)]);
}

export default rootSaga;
