import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';

import {
  motors as motorsModel,
  cartItems as cartModel,
} from '../../../models/products';
import MotorTable from './motor-table';
import ListPrice from '../list-price';
import i18n from '../../../i18n';

export default function MotorView(props) {
  const {
    motors,
    onFilterClick,
    addMotor,
    removeMotor,
    selectedMotor,
    cart,
    setStep,
    activeStep,
    subtypes,
    filterOptions,
    onSelectClick,
    bottomSectionRef,
  } = props;

  const formatText = (text) => {
    const words = text.split(' ');
    let toInsert = 0;
    if (words.length > 4) {
      toInsert = 1;
    }
    words[toInsert] += '\n';
    return words.join(' ');
  };

  return (
    <>
      <h3>2. {i18n.t('priceRequest.motor.title')}</h3>
      <p>{i18n.t('priceRequest.motor.description')}</p>
      <div className="filter-container">
        <div className="gradient-side start" />
        <ButtonGroup toggle className="filter-btn">
          {subtypes.map((subtype, index) => {
            const isSelected = filterOptions.includes(subtype);
            return (
              <ToggleButton
                key={subtype}
                type="checkbox"
                variant="link"
                onChange={() => onFilterClick(subtype)}
                checked={isSelected}
                className={index === subtypes.length - 1 && 'm-0'}
              >
                {isSelected && <CheckIcon />}
                <div>{formatText(subtype)}</div>
              </ToggleButton>
            );
          })}
        </ButtonGroup>
        <div className="gradient-side end" />
      </div>
      <MotorTable
        motors={motors}
        addMotor={addMotor}
        selectedMotor={selectedMotor}
        cart={cart}
        removeMotor={removeMotor}
        onSelectClick={onSelectClick}
      />
      <ListPrice
        nextButtonRef={bottomSectionRef}
        setStep={setStep}
        activeStep={activeStep}
      />
    </>
  );
}

MotorView.propTypes = {
  motors: motorsModel,
  onFilterClick: PropTypes.func,
  addMotor: PropTypes.func,
  removeMotor: PropTypes.func,
  selectedMotor: PropTypes.objectOf(PropTypes.any),
  cart: cartModel,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  filterOptions: PropTypes.instanceOf(Array),
  subtypes: PropTypes.instanceOf(Array),
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
