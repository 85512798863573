import React, { Component } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Button, Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import { accessory } from '../../../../models/products';
import AccessoryModalView from './accessory-modal-view';
import Image from '../../../image';
import PriceField from '../price-field';

class AccessoryModal extends Component {
  static propTypes = {
    data: accessory,
    selectedAccessory: PropTypes.objectOf(PropTypes.any),
    addAccessory: PropTypes.func,
    removeAccessory: PropTypes.func,
    onSelectClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleSelectClick = () => {
    const { data, addAccessory, onSelectClick } = this.props;
    addAccessory(
      data.id,
      data.desc,
      data.net_price,
      data.image_address,
      data.image_alt,
      data.discount_price
    );
    onSelectClick();
  };

  openModal(e) {
    if (!e.key || e.key === 'Enter') {
      this.setState({ isModalOpen: true });
    }
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isModalOpen } = this.state;
    const { data, selectedAccessory, removeAccessory } = this.props;

    if (!data) return null;

    return (
      <>
        <TableRow selected={data.id === selectedAccessory}>
          {/* MOBILE TABLE */}
          <Accordion className="d-md-none">
            <TableCell className="table-product-image pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <Image imageName={data.picture_file_name} alt="accessory" />
              </Accordion.Toggle>
            </TableCell>
            <TableCell component="th" scope="row" className="pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <strong className="text-primary">{data.desc}</strong>
                <div className="mt-1">
                  <PriceField
                    price={data.net_price}
                    discountPrice={data.discount_price}
                    discountPercent={data.discount_percentage}
                  />
                </div>
              </Accordion.Toggle>
              <Card className="invisible-card">
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={data.id}
                  >
                    <small>
                      {i18n.t('priceRequest.send.table.seeAllCharacteristics')}
                      <KeyboardArrowDown className="chevron-down" />
                    </small>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={data.id}>
                  <Card.Body className="mt-3">
                    <p>
                      <strong>
                        {i18n.t(
                          'priceRequest.productModals.accessory.description'
                        )}
                      </strong>
                    </p>
                    <p>{data.desc}</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </TableCell>
          </Accordion>
          {/* MOBILE TABLE END */}

          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell table-product-image p-2"
          >
            <Image imageName={data.picture_file_name} alt="accessory" />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            <strong className="text-primary">{data.desc}</strong>
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          />
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            <PriceField
              price={data.net_price}
              discountPrice={data.discount_price}
              discountPercent={data.discount_percentage}
            />
          </TableCell>
          <TableCell className="text-right text-md-center p-0">
            {selectedAccessory.id === data.id ? (
              <Button
                variant="outline-warning"
                onClick={() => removeAccessory(data.net_price)}
              >
                {i18n.t('priceRequest.buttons.deselect')}
              </Button>
            ) : (
              <Button variant="warning" onClick={this.handleSelectClick}>
                {i18n.t('priceRequest.buttons.select')}
              </Button>
            )}
          </TableCell>
        </TableRow>

        <AccessoryModalView
          isModalOpen={isModalOpen}
          closeModal={this.closeModal}
          data={data}
        />
      </>
    );
  }
}

export default AccessoryModal;
