import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepButton,
  Typography,
  StepConnector,
} from '@material-ui/core';
import { Container } from 'react-bootstrap';
import { Element } from 'react-scroll';

import i18n from '../../i18n';

class StepperView extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.string),
    getStepContent: PropTypes.func,
    setStep: PropTypes.func,
    completeStep: PropTypes.func,
    isStepComplete: PropTypes.func,
  };

  render() {
    const {
      activeStep,
      steps,
      completeStep,
      getStepContent,
      setStep,
      isStepComplete,
    } = this.props;

    return (
      <Element name="5">
        <div className="price-request py-5">
          <Container
            fluid="xl"
            className="bg-white rounded-lg shadow py-5 px-sm-5"
          >
            <h2 className="text-center mb-5">
              {activeStep !== 5
                ? i18n.t('priceRequest.titles.main')
                : i18n.t('priceRequest.titles.summary')}
            </h2>
            <div className="stepper-container">
              <div className="gradient-side start" />
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                nonLinear
                connector={<StepConnector />}
                className={activeStep === 5 ? 'd-none' : ''}
              >
                {steps.map((label, index) => {
                  const buttonProps = {};
                  if (
                    index === 1 ||
                    index === 2 ||
                    index === 3 ||
                    index === 4
                  ) {
                    buttonProps.optional = (
                      <Typography
                        component="span"
                        variant="caption"
                        className="optional"
                      >
                        {i18n.t('priceRequest.titles.optional')}
                      </Typography>
                    );
                  }
                  return (
                    <Step key={label}>
                      <StepButton
                        onClick={() => setStep(index)}
                        completed={isStepComplete(index)}
                        optional={buttonProps.optional}
                        classes={{
                          root:
                            isStepComplete(index) || index === activeStep
                              ? 'step-button-completed'
                              : 'step-button-incomplete',
                        }}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <div className="gradient-side end" />
            </div>
            <Typography component="span">
              {getStepContent(activeStep, completeStep, setStep)}
            </Typography>
          </Container>
        </div>
      </Element>
    );
  }
}

export default StepperView;
