import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import BatteryView from './battery-view';
import {
  cartItems as cartModel,
  batteries as batteriesModel,
  motors as motorsModel,
  ecoMode as ecoModeModel,
} from '../../../models/products';

export default function BatterySelect(props) {
  // load all batteries
  const selectedBattery = useSelector((state) => state.cart.battery);
  const {
    cart,
    addBattery,
    removeBattery,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;
  const { ecoMode } = props.economyMode;
  const { batteries, motors } = props.products;

  ecoMode &&
    // eslint-disable-next-line
    ecoMode.map((item) => {
      item.sailingPlaining = 1.3 * item.flatBottom;
    });

  // @TODO i18n & replace 0.98 with calculated capacity treshold
  batteries &&
    // eslint-disable-next-line
    batteries.map((battery) => {
      if (cart.boatType && battery.total_capacity) {
        // Calculate the closest economy mode item from boat weight (tonnage)
        let closestEcoMode = ecoMode[0];
        ecoMode &&
          // eslint-disable-next-line
          ecoMode.map((item) => {
            if (
              Math.abs(item.tonnage - cart.boatWeight) <
              Math.abs(closestEcoMode.tonnage - cart.boatWeight)
            ) {
              closestEcoMode = item;
            }
          });

        // Set battery total capacity
        if (cart.boatType === 'sailing') {
          battery.approx_running_time = Math.round(
            (battery.total_capacity * 0.98) / closestEcoMode.sailingPlaining
          );
        } else {
          battery.approx_running_time = Math.round(
            (battery.total_capacity * 0.98) / closestEcoMode.flatBottom
          );
        }
      } else {
        battery.approx_running_time = 'Unknown';
      }
    });

  const selectedMotor = motors.find((motor) => motor.id === cart.motor.id);

  const filteredBatteries = selectedMotor
    ? batteries.filter((battery) => {
        return (
          selectedMotor.voltage === battery.system_voltage &&
          selectedMotor.continous_current * 1000 <
            battery.recommended_discharge_current &&
          selectedMotor.max_current * 1000 < battery.max_discharge_current
        );
      })
    : [];

  return (
    <BatteryView
      batteries={filteredBatteries}
      addBattery={addBattery}
      removeBattery={removeBattery}
      selectedBattery={selectedBattery}
      setStep={setStep}
      activeStep={activeStep}
      onSelectClick={onSelectClick}
      bottomSectionRef={bottomSectionRef}
    />
  );
}

BatterySelect.propTypes = {
  cart: cartModel,
  products: PropTypes.shape({
    motors: motorsModel,
    batteries: batteriesModel,
  }),
  ecoMode: ecoModeModel,
  addBattery: PropTypes.func,
  removeBattery: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
