import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ChargerView from './charger-view';
import {
  chargers as chargersModel,
  motors as motorsModel,
  cartItems as cartModel,
} from '../../../models/products';

export default function ChargerSelect(props) {
  // load all chargers
  const selectedCharger = useSelector((state) => state.cart.charger);
  const {
    cart,
    motors,
    chargers,
    addCharger,
    removeCharger,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;

  const selectedMotor = motors.find((motor) => motor.id === cart.motor.id);

  const filteredChargers = selectedMotor
    ? chargers.filter((charger) => charger.voltage === selectedMotor.voltage)
    : [];

  return (
    <ChargerView
      chargers={filteredChargers}
      addCharger={addCharger}
      removeCharger={removeCharger}
      selectedCharger={selectedCharger}
      setStep={setStep}
      activeStep={activeStep}
      onSelectClick={onSelectClick}
      bottomSectionRef={bottomSectionRef}
    />
  );
}

ChargerSelect.propTypes = {
  cart: cartModel,
  motors: motorsModel,
  chargers: chargersModel,
  addCharger: PropTypes.func,
  removeCharger: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
