import React, { Component } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Button, Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import { motor as motorModel, cartItems } from '../../../../models/products';
import MotorModalView from './motor-modal-view';
import Image from '../../../image';
import PriceField from '../price-field';

class MotorModal extends Component {
  static propTypes = {
    data: motorModel,
    cart: cartItems,
    selectedMotor: PropTypes.objectOf(PropTypes.any),
    addMotor: PropTypes.func,
    removeMotor: PropTypes.func,
    onSelectClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleSelectClick = () => {
    const { data, addMotor, onSelectClick } = this.props;
    addMotor(
      data.id,
      data.desc,
      data.net_price,
      data.picture_file_name,
      data.image_alt,
      data.article_number,
      data.discount_price
    );
    onSelectClick();
  };

  openModal(e) {
    if (!e.key || e.key === 'Enter') {
      this.setState({ isModalOpen: true });
    }
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isModalOpen } = this.state;
    const { data, cart, selectedMotor, removeMotor } = this.props;

    if (!data) return null;

    return (
      <>
        <TableRow selected={data.id === selectedMotor}>
          <Accordion className="d-md-none">
            {/* MOBILE TABLE */}
            <TableCell className="table-product-image pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <Image imageName={data.picture_file_name} alt="motor" />
              </Accordion.Toggle>
            </TableCell>
            <TableCell component="th" scope="row" className="pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <strong className="text-primary">{data.desc}</strong>
                <div className="mt-1">
                  <PriceField
                    price={data.net_price}
                    discountPrice={data.discount_price}
                    discountPercent={data.discount_percentage}
                  />
                </div>
              </Accordion.Toggle>
              <Card className="invisible-card">
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={data.id}
                  >
                    <small>
                      {i18n.t('priceRequest.send.table.seeAllCharacteristics')}
                      <KeyboardArrowDown className="chevron-down" />
                    </small>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={data.id}>
                  <Card.Body className="mt-3">
                    <p>
                      <strong>
                        {i18n.t('priceRequest.productModals.motor.voltage')}{' '}
                      </strong>{' '}
                      {data.voltage && `${data.voltage} V`}
                    </p>
                    <p>
                      <strong>
                        {i18n.t(
                          'priceRequest.productModals.motor.continousCurrent'
                        )}{' '}
                      </strong>{' '}
                      {data.continous_current && `${data.continous_current} kW`}
                    </p>
                    <p>
                      <strong>
                        {i18n.t(
                          'priceRequest.productModals.motor.boostMaximal'
                        )}{' '}
                      </strong>{' '}
                      {data.max_current && `${data.max_current} kW`}
                    </p>
                    <p>
                      <strong>
                        {i18n.t(
                          'priceRequest.productModals.motor.maxSuggestedWeight'
                        )}{' '}
                      </strong>
                      {cart.boatType === 'sailing'
                        ? data.max_suggested_boat_weight_sail &&
                          `${String(
                            data.max_suggested_boat_weight_sail * 1000
                          ).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} kg`
                        : data.max_suggested_boat_weight_motor &&
                          `${String(
                            data.max_suggested_boat_weight_motor * 1000
                          ).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} kg`}
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </TableCell>
          </Accordion>
          {/* MOBILE TABLE END */}

          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell table-product-image p-2"
          >
            <Image imageName={data.picture_file_name} alt="motor" />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            <strong className="text-primary">{data.desc}</strong>
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {data.voltage && `${data.voltage} V`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {data.continous_current && `${data.continous_current} kW`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {!!data.max_current && `${data.max_current} kW`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {cart.boatType === 'sailing'
              ? data.max_suggested_boat_weight_sail &&
                `${String(data.max_suggested_boat_weight_sail * 1000).replace(
                  /(.)(?=(\d{3})+$)/g,
                  '$1 '
                )} kg`
              : data.max_suggested_boat_weight_motor &&
                `${String(data.max_suggested_boat_weight_motor * 1000).replace(
                  /(.)(?=(\d{3})+$)/g,
                  '$1 '
                )} kg`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="priceRow d-none d-md-table-cell p-2"
          >
            <PriceField
              price={data.net_price}
              discountPrice={data.discount_price}
              discountPercent={data.discount_percentage}
            />
          </TableCell>
          <TableCell className="text-right text-md-center p-0">
            {selectedMotor.id === data.id ? (
              <Button
                variant="outline-warning"
                onClick={() => removeMotor(data.net_price)}
              >
                {i18n.t('priceRequest.buttons.deselect')}
              </Button>
            ) : (
              <Button variant="warning" onClick={this.handleSelectClick}>
                {i18n.t('priceRequest.buttons.select')}
              </Button>
            )}
          </TableCell>
        </TableRow>

        <MotorModalView
          isModalOpen={isModalOpen}
          closeModal={this.closeModal}
          data={data}
          cart={cart}
        />
      </>
    );
  }
}

export default MotorModal;
