import { connect } from 'react-redux';

import Main from './main';
import { showNotification } from '../../reducers/notifications';
import { getProducts, ecoMode } from '../../reducers/request';

const mapStateToProps = (state) => {
  const { lang } = state.app;
  return { lang };
};

export default connect(mapStateToProps, { showNotification, getProducts, ecoMode })(
  Main
);
