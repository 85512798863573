import PropTypes from 'prop-types';

export const battery = PropTypes.shape({
  id: PropTypes.number.isRequired,
  max_discharge_current: PropTypes.number,
  battery_voltage: PropTypes.number,
  recommended_discharge_current: PropTypes.number,
  total_price: PropTypes.number,
  discount_price: PropTypes.number,
  approx_running_time: PropTypes.number,
  pieces_serial: PropTypes.number,
  description: PropTypes.string,
  pieces_parallel: PropTypes.number,
  picture: PropTypes.string,
  max_dicharge_current_per_piece: PropTypes.number,
  pieces: PropTypes.number,
  system_voltage: PropTypes.number,
  capacity_per_piece: PropTypes.number,
  recommended_discharge_current_per_piece: PropTypes.number,
  total_capacity: PropTypes.number,
});

export const batteries = PropTypes.arrayOf(battery);

export const motor = PropTypes.shape({
  id: PropTypes.number.isRequired,
  max_suggested_boat_weight_motor: PropTypes.number,
  article_number: PropTypes.string,
  motor_type: PropTypes.string,
  net_price: PropTypes.number,
  discount_price: PropTypes.number,
  boat_type: PropTypes.string,
  max_suggested_boat_weight_sail: PropTypes.number,
  continous_current: PropTypes.number,
  max_current: PropTypes.number,
  desc: PropTypes.string,
  voltage: PropTypes.number,
});

export const motors = PropTypes.arrayOf(motor);

export const charger = PropTypes.shape({
  id: PropTypes.number.isRequired,
  charge_current: PropTypes.number,
  time_to_charge: PropTypes.number,
  description: PropTypes.string,
  name: PropTypes.string,
  price_charger: PropTypes.number,
  discount_price: PropTypes.number,
  picture: PropTypes.string,
  voltage: PropTypes.number,
  weight: PropTypes.number,
});

export const chargers = PropTypes.arrayOf(charger);

export const accessory = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  discount_price: PropTypes.number,
});

export const accessories = PropTypes.arrayOf(accessory);

export const cartItems = PropTypes.shape({
  batteries,
  motors,
  chargers,
  accessories,
});

export const ecoMode = PropTypes.shape({
  tonnage: PropTypes.number,
  flatBottom: PropTypes.number,
  sailingPlaining: PropTypes.shape({}),
});
