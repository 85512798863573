import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContactModalView from './contact-modal-view';

class ContactModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    sendUserdata: PropTypes.func,
    isThankYouModalOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEmailInvalid: false,
      isNameInvalid: false,
      isPhoneInvalid: false,
      isMessageInvalid: false,
      name: '',
      email: '',
      phone: '',
      message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitUserdata = this.submitUserdata.bind(this);
    this.closeContactModal = this.closeContactModal.bind(this);
  }

  // Test name and email
  handleInputChange(e) {
    const inputValue = e.target.value;
    let re;

    switch (e.target.id) {
      case 'contactName': // Name validation (check for numbers only)
        re = /\d/;
        if (!re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isNameInvalid: false, name: inputValue });
        } else {
          this.setState({ isNameInvalid: true });
        }
        break;
      case 'contactEmail': // Email validation
        re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isEmailInvalid: false, email: inputValue });
        } else {
          this.setState({ isEmailInvalid: true });
        }
        break;
      case 'contactPhone': // Phone validation only accepts number values starting with: + (and nothing (because its optional))
        re = /^.*$/;
        if (re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isPhoneInvalid: false, phone: inputValue });
        } else {
          this.setState({ isPhoneInvalid: true });
        }
        break;
      case 'contactComment': // Message validation
        if (inputValue.length === 0) {
          this.setState({ isMessageInvalid: true });
        } else {
          this.setState({ isMessageInvalid: false, message: inputValue });
        }
        break;
      default:
        break;
    }
  }

  submitUserdata() {
    const {
      name,
      email,
      phone,
      message,
      isNameInvalid,
      isEmailInvalid,
      isPhoneInvalid,
      isMessageInvalid,
    } = this.state;

    if (
      !isEmailInvalid &&
      !isNameInvalid &&
      !isPhoneInvalid &&
      !isMessageInvalid &&
      name.length !== 0 &&
      email.length !== 0 &&
      message.length !== 0
    ) {
      const { sendUserdata, closeModal } = this.props;

      closeModal();

      // Setting a timeout for a better Thank You modal render
      setTimeout(() => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        sendUserdata(formData);
      }, 150);
    } else {
      if (name.length === 0) {
        this.setState({ isNameInvalid: true });
      }
      if (email.length === 0) {
        this.setState({ isEmailInvalid: true });
      }
      if (message.length === 0) {
        this.setState({ isMessageInvalid: true });
      }
    }
  }

  closeContactModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isModalOpen, closeModal, isThankYouModalOpen } = this.props;

    const {
      isNameInvalid,
      isEmailInvalid,
      isPhoneInvalid,
      isMessageInvalid,
      name,
      email,
      phone,
      message,
    } = this.state;

    const { handleInputChange, submitUserdata, closeContactModal } = this;

    return (
      <ContactModalView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleInputChange={handleInputChange}
        submitUserdata={submitUserdata}
        closeContactModal={closeContactModal}
        isNameInvalid={isNameInvalid}
        isPhoneInvalid={isPhoneInvalid}
        isEmailInvalid={isEmailInvalid}
        isMessageInvalid={isMessageInvalid}
        isThankYouModalOpen={isThankYouModalOpen}
        name={name}
        email={email}
        phone={phone}
        message={message}
      />
    );
  }
}

export default ContactModal;
