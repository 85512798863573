import ApiHandlerService from '../../services/api-handler';
import appConfig from '../../config';

export default class OfferApi {
  static send(variables) {
    const url = appConfig.SEND_OFFER_ENDPOINT;

    const config = {
      method: 'POST',
      body: variables.offer,
    };

    // Use _GET_ https://jsonplaceholder.typicode.com/posts as URL for testing
    return ApiHandlerService.fetch(url, config);
  }
}
