import { connect } from 'react-redux';

import ListPrice from './list-price';
import {
  removeAccessory,
  removeBattery,
  removeCharger,
  removeMotor,
} from '../../../reducers/cart';

const mapStateToProps = ({ cart, app, products }) => {
  return {
    motor: cart && cart.motor,
    battery: cart && cart.battery,
    charger: cart && cart.charger,
    accessory: cart && cart.accessory,
    total: cart && cart.total,
    totalDiscountedPrice: cart && cart.totalDiscountedPrice,
    lang: app.lang,
    products,
  };
};

export default connect(mapStateToProps, {
  removeAccessory,
  removeBattery,
  removeCharger,
  removeMotor,
})(ListPrice);
