const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_BASE_URL_INTERNAL:
    process.env.REACT_APP_API_BASE_URL_INTERNAL ||
    process.env.REACT_APP_API_BASE_URL,
  LOCAL_API_BASE_URL: process.env.REACT_APP_LOCAL_API_BASE_URL,
  SAGA_BUFFER_SIZE: process.env.REACT_APP_SAGA_BUFFER_SIZE,
  PRISMIC_ENDPOINT: process.env.REACT_APP_PRISMIC_ENDPOINT,
  PRISMIC_ACCESS_TOKEN: process.env.REACT_APP_PRISMIC_ACCESS_TOKEN,
  SEND_OFFER_ENDPOINT: process.env.REACT_APP_SEND_OFFER_ENDPOINT,
  SEND_USER_DATA_ENDPOINT: process.env.REACT_APP_SEND_USER_DATA_ENDPOINT,
  SUBSCRIBE_ENDPOINT: process.env.REACT_APP_SUBSCRIBE_ENDPOINT,
  PRODUCTS_ENDPOINT: process.env.REACT_APP_PRODUCTS_ENDPOINT,
  ECONOMY_MODE_ENDPOINT: process.env.REACT_APP_ECONOMY_MODE_ENDPOINT,
  IMAGES_ROOT_URL: process.env.REACT_APP_IMAGES_ROOT_URL,
};

export default config;
