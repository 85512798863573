import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import MainView from './main-view';
import cmsService from '../../services/cms/cms-service';

class Main extends Component {
  static propTypes = {
    getProducts: PropTypes.func,
    ecoMode: PropTypes.func,
    lang: PropTypes.string,
    showNotification: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { doc: {} };
  }

  // Get Prismic data on init
  componentDidMount() {
    this.updateCMS();
  }

  // Update Prismic data on language change
  componentDidUpdate(prevProps) {
    const { lang } = this.props;

    if (lang !== prevProps.lang) {
      this.updateCMS();
    }
  }

  updateCMS = async () => {
    const { lang, getProducts, ecoMode, showNotification } = this.props;

    try {
      const cmsData = await cmsService.getData(showNotification, lang);

      this.setState({ doc: cmsData });
    } catch (error) {
      showNotification({
        type: 'error',
      });
    }

    // load products
    getProducts(lang);
    ecoMode();
  };

  render() {
    const { lang } = this.props;
    const { doc } = this.state;

    i18n.locale = lang;

    return <MainView data={doc} />;
  }
}

export default Main;
