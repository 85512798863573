import React, { Component } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Button, Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import { battery } from '../../../../models/products';
import BatteryModalView from './battery-modal-view';
import Image from '../../../image';
import PriceField from '../price-field';

class BatteryModal extends Component {
  static propTypes = {
    data: battery,
    selectedBattery: PropTypes.objectOf(PropTypes.any),
    addBattery: PropTypes.func,
    removeBattery: PropTypes.func,
    onSelectClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleSelectClick = () => {
    const { data, addBattery, onSelectClick } = this.props;
    addBattery(
      data.id,
      data.description,
      data.total_price,
      data.picture_file_name,
      data.image_alt,
      data.discount_price
    );
    onSelectClick();
  };

  openModal(e) {
    if (!e.key || e.key === 'Enter') {
      this.setState({ isModalOpen: true });
    }
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const { isModalOpen } = this.state;
    const { data, selectedBattery, removeBattery } = this.props;

    if (!data) return null;

    return (
      <>
        <TableRow selected={data.id === selectedBattery}>
          {/* MOBILE TABLE */}
          <Accordion className="d-md-none">
            <TableCell className="table-product-image pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <Image imageName={data.picture_file_name} alt="battery" />
              </Accordion.Toggle>
            </TableCell>
            <TableCell component="th" scope="row" className="pr-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={data.id}
                className="o-100 text-left p-0"
              >
                <strong className="text-primary">{data.description}</strong>
                <div className="mt-1">
                  <PriceField
                    price={data.total_price}
                    discountPrice={data.discount_price}
                    discountPercent={data.discount_percent}
                  />
                </div>
              </Accordion.Toggle>
              <Card className="invisible-card">
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={data.id}
                  >
                    <small>
                      {i18n.t('priceRequest.send.table.seeAllCharacteristics')}
                      <KeyboardArrowDown className="chevron-down" />
                    </small>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={data.id}>
                  <Card.Body className="mt-3">
                    <p>
                      <strong>
                        {i18n.t(
                          'priceRequest.productModals.battery.approxRunningTime'
                        )}{' '}
                      </strong>{' '}
                      {data.approx_running_time &&
                        `${data.approx_running_time} ${i18n.t(
                          'priceRequest.productModals.hours'
                        )}`}
                    </p>
                    <p>
                      <strong>
                        {i18n.t('priceRequest.productModals.battery.pieces')}{' '}
                      </strong>
                      {data.pieces}
                    </p>
                    <p>
                      <strong>
                        {i18n.t('priceRequest.productModals.battery.voltage')}{' '}
                      </strong>{' '}
                      {data.system_voltage && `${data.system_voltage} V`}
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </TableCell>
          </Accordion>
          {/* MOBILE TABLE END */}

          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell table-product-image p-2"
          >
            <Image imageName={data.picture_file_name} alt="battery" />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            <strong className="text-primary">{data.description}</strong>
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {data.total_capacity &&
              `${String(data.total_capacity).replace(
                /(.)(?=(\d{3})+$)/g,
                '$1 '
              )} Wh`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            {data.approx_running_time &&
              `${data.approx_running_time} ${i18n.t(
                'priceRequest.productModals.hours'
              )}`}
          </TableCell>
          <TableCell
            role="button"
            tabIndex="0"
            onKeyDown={this.openModal}
            onClick={this.openModal}
            className="d-none d-md-table-cell p-2"
          >
            <PriceField
              price={data.total_price}
              discountPrice={data.discount_price}
              discountPercent={data.discount_percent}
            />
          </TableCell>
          <TableCell className="text-right text-md-center p-0">
            {selectedBattery.id === data.id ? (
              <Button
                variant="outline-warning"
                onClick={() => removeBattery(data.total_price)}
              >
                {i18n.t('priceRequest.buttons.deselect')}
              </Button>
            ) : (
              <Button variant="warning" onClick={this.handleSelectClick}>
                {i18n.t('priceRequest.buttons.select')}
              </Button>
            )}
          </TableCell>
        </TableRow>

        <BatteryModalView
          isModalOpen={isModalOpen}
          closeModal={this.closeModal}
          data={data}
        />
      </>
    );
  }
}

export default BatteryModal;
