const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const REGISTER_TOKEN = 'REGISTER_TOKEN';
const DELETE_TOKEN = 'DELETE_TOKEN';

export const Types = {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  REGISTER_TOKEN,
  DELETE_TOKEN,
};

export const MessageTypes = {
  Error: 'error',
  Info: 'info',
  Warning: 'warning',
  Success: 'success',
};

export const initialState = [];

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return !state.find(
        (notification) => notification.uid === action.payload.uid
      )
        ? [action.payload, ...state]
        : state;
    }
    case HIDE_NOTIFICATION:
      return state.filter((notification) => notification.uid !== action.uid);
    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const showNotification = (payload) => ({
  type: Types.SHOW_NOTIFICATION,
  payload,
});

export const hideNotification = (uid) => ({
  type: Types.HIDE_NOTIFICATION,
  uid,
});

export const registerToken = (payload) => ({
  type: Types.REGISTER_TOKEN,
  payload,
});

export const deleteToken = (payload) => ({
  type: Types.DELETE_TOKEN,
  payload,
});

export const Actions = {
  showNotification,
  hideNotification,
  registerToken,
  deleteToken,
};

export default notifications;
