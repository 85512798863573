import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import ChargerTable from './charger-table/charger-table';
import { chargers as chargersModel } from '../../../models/products';
import ListPrice from '../list-price';

export default function ChargerView(props) {
  const {
    chargers,
    selectedCharger,
    addCharger,
    removeCharger,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;

  return (
    <>
      <h3 className="mx-15">{i18n.t('priceRequest.titles.charger')}</h3>
      <ChargerTable
        chargers={chargers}
        addCharger={addCharger}
        removeCharger={removeCharger}
        selectedCharger={selectedCharger}
        onSelectClick={onSelectClick}
      />
      <ListPrice
        nextButtonRef={bottomSectionRef}
        setStep={setStep}
        activeStep={activeStep}
      />
    </>
  );
}

ChargerView.propTypes = {
  chargers: chargersModel,
  selectedCharger: PropTypes.objectOf(PropTypes.any),
  addCharger: PropTypes.func,
  removeCharger: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
