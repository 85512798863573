import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Button, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-scroll';

import { richText, img } from '../../models/cms';
import i18n from '../../i18n';

export default class SlideshowView extends Component {
  static propTypes = {
    title: richText,
    bg: PropTypes.shape({
      desktop: img,
      mobile: img,
    }),
  };

  render() {
    const { title, bg } = this.props;

    return (
      <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <img
            className="desktop-bg"
            src={bg.desktop?.url}
            alt={bg.desktop?.alt}
          />
          <img
            className="mobile-bg"
            src={bg.mobile?.url}
            alt={bg.mobile?.alt}
          />
          <Carousel.Caption className="px-3">
            <div className="ml-2 text-center text-md-left">
              <h1>{title && RichText.asText(title).replace('-', '‑')}</h1>
            </div>
            <Row>
              <Col xs={12} md={5} className="header-btn">
                <Link to="5" smooth duration={400}>
                  <Button variant="primary" className="mt-4 mx-0 mx-md-2 w-100">
                    {i18n.t('slideshow.button1')}
                  </Button>
                </Link>
              </Col>
              <Col xs={12} md={5} className="header-btn">
                <Link to="3" smooth duration={400}>
                  <Button
                    variant="outline-light"
                    className="mt-4 mx-0 mx-md-2 w-100"
                  >
                    {i18n.t('slideshow.button2')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
