import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BoatInfoView from './boatinfo-view';
import { cartItems as cartModel } from '../../../models/products';

export default class BoatInfo extends Component {
  static propTypes = {
    cart: cartModel,
    activeStep: PropTypes.number,
    setBoatType: PropTypes.func,
    setMotorType: PropTypes.func,
    setBoatWeight: PropTypes.func,
    setStep: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isBoatTypeInvalid: false,
      isMotorTypeInvalid: false,
      isWeightInvalid: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);

    this.sailingCheckbox = React.createRef();
    this.motorboatCheckbox = React.createRef();
    this.builtInCheckBox = React.createRef();
    this.onDeckCheckbox = React.createRef();
    this.weightInput = React.createRef();
  }

  onNextClick() {
    const {
      isBoatTypeInvalid,
      isMotorTypeInvalid,
      isWeightInvalid,
    } = this.state;

    const { activeStep } = this.props;

    // Using local states as global states are updated asyncronously
    let iBTI = isBoatTypeInvalid;
    let iMTI = isMotorTypeInvalid;
    let iWI = isWeightInvalid;

    let weight = this.weightInput.current.value;

    if (
      !this.sailingCheckbox.current.checked &&
      !this.motorboatCheckbox.current.checked
    ) {
      iBTI = true;
      this.setState({ isBoatTypeInvalid: iBTI });
    }

    if (
      !this.builtInCheckBox.current.checked &&
      !this.onDeckCheckbox.current.checked
    ) {
      iMTI = true;
      this.setState({ isMotorTypeInvalid: iMTI });
    }

    if (weight.length === 0) {
      iWI = true;
      this.setState({ isWeightInvalid: iWI });
    }

    if (!iBTI && !iMTI && !iWI) {
      const { setBoatType, setMotorType, setBoatWeight, setStep } = this.props;

      let boatType;
      let motorType;

      // Boat type check
      if (this.sailingCheckbox.current.checked) {
        boatType = 'sailing';
      } else {
        boatType = 'motorboat';
      }

      // Motor type check
      if (this.builtInCheckBox.current.checked) {
        motorType = 'inner';
      } else {
        motorType = 'outer';
      }

      // Convert weight input to integer and divide by 1000
      weight = parseInt(weight, 10);
      weight /= 1000;

      setBoatType(boatType);
      setMotorType(motorType);
      setBoatWeight(weight);

      setStep(activeStep + 1);
    }
  }

  // Reset invalid states
  handleCheckboxChange(e) {
    const { setBoatType, setMotorType } = this.props;

    let boatType;
    let motorType;

    if (e.target.name === 'boatTypeCheckbox') {
      this.setState({ isBoatTypeInvalid: false });

      if (e.target.id === 'sailingCheckbox') {
        boatType = 'sailing';
      } else {
        boatType = 'motorboat';
      }

      setBoatType(boatType);
    } else if (e.target.name === 'motorTypeCheckbox') {
      this.setState({ isMotorTypeInvalid: false });

      if (e.target.id === 'builtInCheckbox') {
        motorType = 'inner';
      } else {
        motorType = 'outer';
      }

      setMotorType(motorType);
    }
  }

  // Weight input change
  handleInputChange(e) {
    const { setBoatWeight } = this.props;
    const re = /^\d+$/; // Numbers only
    const inputValue = e.target.value;

    if (re.test(inputValue) || inputValue.length === 0) {
      this.setState({ isWeightInvalid: false });

      // This is needed becase the displayed number is 1000 times the boatWeight stored in Redux
      setBoatWeight(inputValue / 1000);
    } else {
      // @TODO: @dh - check on this one
      // this.setState({ isWeightInvalid: true });
    }
  }

  render() {
    const {
      sailingCheckbox,
      motorboatCheckbox,
      builtInCheckBox,
      onDeckCheckbox,
      weightInput,
      handleCheckboxChange,
      handleInputChange,
      onNextClick,
    } = this;

    const {
      isBoatTypeInvalid,
      isMotorTypeInvalid,
      isWeightInvalid,
    } = this.state;

    const { cart } = this.props;

    const { boatType, motorType, boatWeight } = cart;

    return (
      <BoatInfoView
        sailingCheckbox={sailingCheckbox}
        motorboatCheckbox={motorboatCheckbox}
        builtInCheckBox={builtInCheckBox}
        onDeckCheckbox={onDeckCheckbox}
        weightInput={weightInput}
        handleCheckboxChange={handleCheckboxChange}
        handleInputChange={handleInputChange}
        onNextClick={onNextClick}
        isBoatTypeInvalid={isBoatTypeInvalid}
        isMotorTypeInvalid={isMotorTypeInvalid}
        isWeightInvalid={isWeightInvalid}
        boatType={boatType}
        motorType={motorType}
        boatWeight={boatWeight.toString()}
      />
    );
  }
}
