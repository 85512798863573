import React, { Component } from 'react';
import { Row, Col, Table, Form, Button, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import config from '../../../config';
import { reactRef } from '../../../models/react';
import { cartItems as cartItemsModel } from '../../../models/products';
import { richText } from '../../../models/cms';
import ThankYouModal from '../../thank-you-modal';
import RestartOrderModal from '../../restart-order-modal';
import PrivacyPolicyModal from '../../privacy-policy-modal';
import Image from '../../image';

export default class PriceRequestView extends Component {
  static propTypes = {
    cart: cartItemsModel,
    showTables: PropTypes.bool,
    doesInclude: PropTypes.bool,
    removeAccessory: PropTypes.func,
    removeBattery: PropTypes.func,
    removeCharger: PropTypes.func,
    removeMotor: PropTypes.func,
    handleInputChange: PropTypes.func,
    isEmailInvalid: PropTypes.bool,
    isNameInvalid: PropTypes.bool,
    backToEditingClick: PropTypes.func,
    sendRequestClick: PropTypes.func,
    restartOrder: PropTypes.func,
    isRestartModalOpen: PropTypes.bool,
    openRestartModal: PropTypes.func,
    closeRestartModal: PropTypes.func,
    openPrivacyPolicyModal: PropTypes.func,
    closePrivacyPolicyModal: PropTypes.func,
    isPrivacyPolicyModalOpen: PropTypes.bool,
    isThankYouModalOpen: PropTypes.bool,
    tyTitle: richText,
    tyDesc: richText,
    privacyPolicy: richText,
    termsAndConditions: richText,
    installationCheckbox: reactRef,
    acceptCheckbox: reactRef,
    onAcceptCheckboxChange: PropTypes.func,
    isAcceptCheckboxChecked: PropTypes.bool,
    isAcceptCheckboxInvalid: PropTypes.bool,
    acceptPrivacyPolicy: PropTypes.func,
    nameInput: reactRef,
    emailInput: reactRef,
    phoneInput: reactRef,
    commentInput: reactRef,
  };

  render() {
    const {
      cart,
      showTables,
      doesInclude,
      removeAccessory,
      removeBattery,
      removeCharger,
      removeMotor,
      handleInputChange,
      isEmailInvalid,
      isNameInvalid,
      backToEditingClick,
      sendRequestClick,
      restartOrder,
      isRestartModalOpen,
      openRestartModal,
      closeRestartModal,
      openPrivacyPolicyModal,
      closePrivacyPolicyModal,
      isPrivacyPolicyModalOpen,
      isThankYouModalOpen,
      tyTitle,
      tyDesc,
      privacyPolicy,
      termsAndConditions,
      installationCheckbox,
      acceptCheckbox,
      onAcceptCheckboxChange,
      isAcceptCheckboxChecked,
      isAcceptCheckboxInvalid,
      acceptPrivacyPolicy,
      nameInput,
      emailInput,
      phoneInput,
      commentInput,
    } = this.props;

    return (
      <>
        <Row>
          <Col xs={12} md={6} lg={7} className="price-request-col">
            <div className={showTables ? '' : 'd-none'}>
              <Table className="table-borderless">
                <thead>
                  <tr>
                    <th>
                      <h5>{i18n.t('priceRequest.send.table.selectedItems')}</h5>
                    </th>
                    <th />
                    <th>
                      <h5>{i18n.t('priceRequest.send.table.listPrice')}</h5>
                    </th>
                    {cart.motor.discount_price &&
                    cart.battery.discount_price &&
                    cart.charger.discount_price &&
                    cart.accessory.discount_price ? (
                      <th />
                    ) : (
                      ''
                    )}
                    <th />
                  </tr>
                </thead>
                <tbody className="fs-14">
                  <tr
                    className={
                      Object.entries(cart.motor).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="text-primary w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={cart.motor.imageAddress}
                            alt="motor"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          <strong>{cart.motor.name}</strong>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <span className="fs-12">×</span> 1
                    </td>
                    <PriceCell
                      price={cart.motor.price}
                      discountedPrice={cart.motor.discountedPrice}
                    />
                    {cart.motor.discount_price &&
                    cart.battery.discount_price &&
                    cart.charger.discount_price &&
                    cart.accessory.discount_price ? (
                      <td />
                    ) : (
                      ''
                    )}
                    <td>
                      <div
                        role="button"
                        onClick={() => removeMotor(cart.motor.price)}
                        onKeyDown={() => removeMotor(cart.motor.price)}
                        tabIndex="0"
                      >
                        <img
                          src={`${config.IMAGES_ROOT_URL}/x.svg`}
                          alt="x"
                          title={i18n.t('priceRequest.buttons.removeMotor')}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={
                      Object.entries(cart.battery).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="text-primary w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={cart.battery.imageAddress}
                            alt="battery"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          <strong>{cart.battery.name}</strong>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <span className="fs-12">×</span> 1
                    </td>
                    <PriceCell
                      price={cart.battery.price}
                      discountedPrice={cart.battery.discountedPrice}
                    />
                    {cart.motor.discount_price &&
                    cart.battery.discount_price &&
                    cart.charger.discount_price &&
                    cart.accessory.discount_price ? (
                      <td />
                    ) : (
                      ''
                    )}
                    <td>
                      <div
                        role="button"
                        onClick={() => removeBattery(cart.battery.price)}
                        onKeyDown={() => removeBattery(cart.battery.price)}
                        tabIndex="0"
                      >
                        <img
                          src={`${config.IMAGES_ROOT_URL}/x.svg`}
                          alt="x"
                          title={i18n.t('priceRequest.buttons.removeBattery')}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={
                      Object.entries(cart.charger).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="text-primary w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={cart.charger.imageAddress}
                            alt="charger"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          <strong>{cart.charger.name}</strong>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <span className="fs-12">×</span> 1
                    </td>
                    <PriceCell
                      price={cart.charger.price}
                      discountedPrice={cart.charger.discountedPrice}
                    />
                    {cart.motor.discount_price &&
                    cart.battery.discount_price &&
                    cart.charger.discount_price &&
                    cart.accessory.discount_price ? (
                      <td />
                    ) : (
                      ''
                    )}
                    <td>
                      <div
                        role="button"
                        onClick={() => removeCharger(cart.charger.price)}
                        onKeyDown={() => removeCharger(cart.charger.price)}
                        tabIndex="0"
                      >
                        <img
                          src={`${config.IMAGES_ROOT_URL}/x.svg`}
                          alt="x"
                          title={i18n.t('priceRequest.buttons.removeCharger')}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={
                      Object.entries(cart.accessory).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="text-primary w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={cart.accessory.imageAddress}
                            alt="accessory"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          <strong>{cart.accessory.name}</strong>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <span className="fs-12">×</span> 1
                    </td>
                    <PriceCell
                      price={cart.accessory.price}
                      discountedPrice={cart.accessory.discountedPrice}
                    />
                    {cart.motor.discount_price &&
                    cart.battery.discount_price &&
                    cart.charger.discount_price &&
                    cart.accessory.discount_price ? (
                      <td />
                    ) : (
                      ''
                    )}
                    <td>
                      <div
                        role="button"
                        onClick={() => removeAccessory(cart.accessory.price)}
                        onKeyDown={() => removeAccessory(cart.accessory.price)}
                        tabIndex="0"
                      >
                        <img
                          src={`${config.IMAGES_ROOT_URL}/x.svg`}
                          alt="x"
                          title={i18n.t('priceRequest.buttons.removeAccessory')}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className={doesInclude ? '' : 'd-none'}>
              <Table className="table-borderless">
                <thead>
                  <tr>
                    <th>
                      <h5>
                        {i18n.t(
                          'priceRequest.send.table.includedWithYourOrder'
                        )}
                      </h5>
                    </th>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody className="fs-14">
                  <tr
                    className={
                      Object.entries(cart.motor).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={`${config.IMAGES_ROOT_URL}/battery-monitor.png`}
                            alt="battery monitor"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          {i18n.t(
                            'priceRequest.send.table.includedBatteryMonitor'
                          )}
                        </Col>
                      </Row>
                    </td>
                    <td className="piece">
                      <span className="fs-12">×</span> 1
                    </td>
                    <td>{i18n.t('priceRequest.send.table.included')}</td>
                    <td className="text-muted">0 HUF</td>
                  </tr>
                  <tr
                    className={
                      Object.entries(cart.battery).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={`${config.IMAGES_ROOT_URL}/battery-management.png`}
                            alt="battery management"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          {i18n.t(
                            'priceRequest.send.table.includedBatteryManagement'
                          )}
                        </Col>
                      </Row>
                    </td>
                    <td className="piece">
                      <span className="fs-12">×</span> 1
                    </td>
                    <td>{i18n.t('priceRequest.send.table.included')}</td>
                    <td className="text-muted">0 HUF</td>
                  </tr>
                  <tr
                    className={
                      Object.entries(cart.battery).length > 0 ? '' : 'd-none'
                    }
                  >
                    <td className="w-50">
                      <Row>
                        <Col xs={3}>
                          <Image
                            imageName={`${config.IMAGES_ROOT_URL}/included-accessory.png`}
                            alt="included accessory"
                            className="mr-3 mw-100"
                          />
                        </Col>
                        <Col xs={9}>
                          {i18n.t(
                            'priceRequest.send.table.includedAccessories'
                          )}
                        </Col>
                      </Row>
                    </td>
                    <td className="piece">
                      <span className="fs-12">×</span> 1
                    </td>
                    <td>{i18n.t('priceRequest.send.table.included')}</td>
                    <td className="text-muted">0 HUF</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className={showTables ? '' : 'd-none'}>
              <hr />
              <Table className="table-borderless">
                <thead>
                  <tr>
                    <th>
                      <h5>{i18n.t('priceRequest.send.table.total')}:</h5>
                    </th>
                    <th />
                    <th />
                    <th />
                    <th className="text-right product-price total">
                      <h5>
                        <div
                          className={
                            cart.total > cart.totalDiscountedPrice &&
                            'line-through'
                          }
                        >
                          {String(cart.total).replace(
                            /(.)(?=(\d{3})+$)/g,
                            '$1 '
                          )}{' '}
                          HUF
                        </div>
                        {cart.total > cart.totalDiscountedPrice && (
                          <div className="discount-price">
                            {String(cart.totalDiscountedPrice).replace(
                              /(.)(?=(\d{3})+$)/g,
                              '$1 '
                            )}{' '}
                            HUF
                          </div>
                        )}
                      </h5>
                    </th>
                  </tr>
                </thead>
              </Table>
              <Form>
                <Form.Group
                  className="m-0 mx-md-3"
                  controlId="installationCheckbox"
                >
                  <Form.Check
                    ref={installationCheckbox}
                    type="checkbox"
                    className="d-inline-block"
                  />
                  <strong className="fs-18">
                    {i18n.t('priceRequest.send.installationCheckbox')}
                  </strong>
                </Form.Group>
              </Form>
            </div>
            <div
              className={`${showTables ? 'd-none' : ''} w-100 text-center my-5`}
            >
              <h5>{i18n.t('priceRequest.send.table.noSelected')}</h5>
            </div>
            <div className="divider-horizontal" />
          </Col>
          <Col xs={12} md={6} lg={5} className="price-request-col">
            <Form>
              <Form.Group controlId="prName">
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Label>
                      {i18n.t('priceRequest.send.name.label')}
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Control.Feedback
                      type="invalid"
                      className={isNameInvalid ? 'd-block' : ''}
                    >
                      {i18n.t('priceRequest.send.name.invalidMsg')}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Form.Control
                  ref={nameInput}
                  type="text"
                  placeholder={i18n.t('priceRequest.send.name.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isNameInvalid}
                />
              </Form.Group>

              <Form.Group controlId="prPhone">
                <Form.Label>
                  {i18n.t('priceRequest.send.phone.label')}
                </Form.Label>
                <Form.Control
                  ref={phoneInput}
                  type="text"
                  onChange={handleInputChange}
                  placeholder={i18n.t('priceRequest.send.phone.placeholder')}
                />
              </Form.Group>

              <Form.Group controlId="prEmail">
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Label>
                      {i18n.t('priceRequest.send.email.label')}
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Control.Feedback
                      type="invalid"
                      className={isEmailInvalid ? 'd-block' : ''}
                    >
                      {i18n.t('priceRequest.send.email.invalidMsg')}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Form.Control
                  ref={emailInput}
                  type="email"
                  placeholder={i18n.t('priceRequest.send.email.placeholder')}
                  onChange={(e) => handleInputChange(e)}
                  isInvalid={isEmailInvalid}
                />
              </Form.Group>

              <Form.Group controlId="prComment">
                <Form.Label>
                  {i18n.t('priceRequest.send.message.label')}
                </Form.Label>
                <Form.Control
                  ref={commentInput}
                  type="text"
                  onChange={handleInputChange}
                  placeholder={i18n.t('priceRequest.send.message.placeholder')}
                />
              </Form.Group>

              <Form.Group controlId="prCheckbox">
                <Form.Check type="checkbox" className="terms">
                  <Form.Check.Input
                    type="checkbox"
                    ref={acceptCheckbox}
                    isInvalid={isAcceptCheckboxInvalid}
                    checked={isAcceptCheckboxChecked}
                    onChange={onAcceptCheckboxChange}
                  />
                  <Form.Check.Label className="fs-14">
                    {i18n.t('priceRequest.send.checkbox.label.before')}
                    <strong
                      role="button"
                      onClick={openPrivacyPolicyModal}
                      onKeyDown={openPrivacyPolicyModal}
                      tabIndex="0"
                      className="text-primary"
                    >
                      {i18n.t('priceRequest.send.checkbox.terms')}
                    </strong>
                    {i18n.t('priceRequest.send.checkbox.label.between')}
                    <strong
                      role="button"
                      onClick={openPrivacyPolicyModal}
                      onKeyDown={openPrivacyPolicyModal}
                      tabIndex="0"
                      className="text-primary"
                    >
                      {i18n.t('priceRequest.send.checkbox.policy')}
                    </strong>
                    {i18n.t('priceRequest.send.checkbox.label.after')}
                  </Form.Check.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    className="error-text fs-12"
                  >
                    {i18n.t('priceRequest.send.checkbox.invalidMsg')}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>

              <Row>
                <Badge
                  variant="warning"
                  className="px-2 py-3 w-100 mx-3 mb-3 fs-14"
                >
                  {i18n.t('priceRequest.send.badge.text')}
                </Badge>
              </Row>

              <Row>
                <Col xs={12} sm={6} className="back-btn">
                  <Button
                    variant="outline-primary"
                    onClick={backToEditingClick}
                    className="w-100 my-1"
                  >
                    {i18n.t('priceRequest.send.backToEditing')}
                  </Button>
                </Col>
                <Col xs={12} sm={6} className="send-btn">
                  <Button
                    variant="primary"
                    onClick={sendRequestClick}
                    className="w-100 my-1"
                  >
                    {i18n.t('priceRequest.send.sendRequest')}
                  </Button>
                </Col>
              </Row>
              <Col xs={12} className="text-center mt-3">
                <Button variant="link" onClick={openRestartModal}>
                  {i18n.t('priceRequest.send.restartOrder')}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
        <RestartOrderModal
          isModalOpen={isRestartModalOpen}
          closeModal={closeRestartModal}
          restartOrder={restartOrder}
        />
        <ThankYouModal
          isModalOpen={isThankYouModalOpen}
          title={tyTitle}
          desc={tyDesc}
        />
        <PrivacyPolicyModal
          isModalOpen={isPrivacyPolicyModalOpen}
          closeModal={closePrivacyPolicyModal}
          acceptBtnClick={acceptPrivacyPolicy}
          termsAndConditions={termsAndConditions}
          privacyPolicy={privacyPolicy}
        />
      </>
    );
  }
}

function PriceCell({ price, discountedPrice }) {
  return (
    <td style={{ width: 120 + 'px' }} className="product-price">
      <div className={discountedPrice ? 'line-through' : ''}>
        {String(price).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} HUF
      </div>
      {!!discountedPrice && (
        <div className="discount-price">
          {String(discountedPrice).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} HUF
        </div>
      )}
    </td>
  );
}

PriceCell.propTypes = {
  price: PropTypes.number,
  discountedPrice: PropTypes.number,
};
