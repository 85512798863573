import { REQUEST, REHYDRATE } from '../request';

const ECOMODE_SUCCESS = 'ECOMODE_SUCCESS';

export const Types = {
  ECOMODE_SUCCESS,
  REQUEST,
};

export const initialState = {
  isFetching: false,
  ecoMode: [
    {
      tonnage: null,
      flatBottom: null,
      sailingPlaining: null,
    },
  ],
  errors: null,
};

const economyMode = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /ECOMODE/.test(action.payload.action)) ||
          state.isFetching,
        errors:
          action.payload && /ECOMODE/.test(action.payload.action)
            ? null
            : state.errors,
      };

    case ECOMODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...(action.payload || []),
        errors: null,
      };

    case REHYDRATE:
      return {
        ...((action.payload && action.payload.products) || initialState),
        isFetching: false,
      };

    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const Actions = {};

export default economyMode;
