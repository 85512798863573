import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import AccessoryTable from './accessory-table/accessory-table';
import { accessories as accessoriesModel } from '../../../models/products';
import ListPrice from '../list-price';

export default function AccessoryView(props) {
  const {
    accessories,
    selectedAccessory,
    addAccessory,
    removeAccessory,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;

  return (
    <>
      <h3 className="mx-15">{i18n.t('priceRequest.titles.accessory')}</h3>
      <AccessoryTable
        accessories={accessories}
        addAccessory={addAccessory}
        removeAccessory={removeAccessory}
        selectedAccessory={selectedAccessory}
        onSelectClick={onSelectClick}
      />
      <ListPrice
        nextButtonRef={bottomSectionRef}
        setStep={setStep}
        activeStep={activeStep}
      />
    </>
  );
}

AccessoryView.propTypes = {
  accessories: accessoriesModel,
  selectedAccessory: PropTypes.objectOf(PropTypes.any),
  addAccessory: PropTypes.func,
  removeAccessory: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
