import ApiHandlerService from '../../services/api-handler';
import appConfig from '../../config';

export default class EconomyModeApi {
  static list(variables, token) {
    const username = variables && variables.username;
    const limit = variables && variables.limit;

    // concat query parameters
    const params = {};
    if (username) params.user_name = username;
    if (limit) params.limit = limit;

    const url = appConfig.ECONOMY_MODE_ENDPOINT;

    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
      },
      method: 'GET',
    };

    return ApiHandlerService.fetch(url, config);
  }
}
