import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { cartItems as cartItemsModel } from '../../../models/products';
import { richText } from '../../../models/cms';
import PriceRequestView from './price-request-view';

class PriceRequest extends Component {
  static propTypes = {
    setFirstStep: PropTypes.func,
    unCompleteAllSteps: PropTypes.func,
    cart: cartItemsModel,
    isThankYouModalOpen: PropTypes.bool,
    removeAccessory: PropTypes.func,
    removeBattery: PropTypes.func,
    removeCharger: PropTypes.func,
    removeMotor: PropTypes.func,
    termsAndConditions: richText,
    privacyPolicy: richText,
    tyTitle: richText,
    tyDesc: richText,
    flushCart: PropTypes.func,
    sendOffer: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTables: false,
      doesInclude: false,
      isEmailInvalid: false,
      isNameInvalid: false,
      isRestartModalOpen: false,
      isPrivacyPolicyModalOpen: false,
      isAcceptCheckboxChecked: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onAcceptCheckboxChange = this.onAcceptCheckboxChange.bind(this);
    this.restartOrder = this.restartOrder.bind(this);
    this.backToEditingClick = this.backToEditingClick.bind(this);
    this.sendRequestClick = this.sendRequestClick.bind(this);

    this.openRestartModal = this.openRestartModal.bind(this);
    this.closeRestartModal = this.closeRestartModal.bind(this);
    this.openPrivacyPolicyModal = this.openPrivacyPolicyModal.bind(this);
    this.closePrivacyPolicyModal = this.closePrivacyPolicyModal.bind(this);
    this.acceptPrivacyPolicy = this.acceptPrivacyPolicy.bind(this);

    this.installationCheckbox = React.createRef();
    this.acceptCheckbox = React.createRef();
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.phoneInput = React.createRef();
    this.commentInput = React.createRef();
  }

  componentDidMount() {
    this.handleTableDisplay();
  }

  onAcceptCheckboxChange() {
    const { isAcceptCheckboxChecked } = this.state;
    this.setState({ isAcceptCheckboxChecked: !isAcceptCheckboxChecked });
  }

  // Check if any products selected
  handleTableDisplay() {
    const { cart } = this.props;

    if (
      Object.entries(cart.motor).length === 0 &&
      Object.entries(cart.battery).length === 0 &&
      Object.entries(cart.charger).length === 0 &&
      Object.entries(cart.accessory).length === 0
    ) {
      this.setState({ showTables: false });
    } else {
      this.setState({ showTables: true });
    }

    if (
      Object.entries(cart.motor).length === 0 &&
      Object.entries(cart.battery).length === 0
    ) {
      this.setState({ doesInclude: false });
    } else {
      this.setState({ doesInclude: true });
    }
  }

  // Test name and email
  handleInputChange(e) {
    const inputValue = e.target.value;
    let re;

    switch (e.target.id) {
      case 'prName': // Name validation (check for numbers only)
        re = /\d/;
        if (!re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isNameInvalid: false });
        } else {
          this.setState({ isNameInvalid: true });
        }
        break;
      case 'prEmail': // Email validation
        re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isEmailInvalid: false });
        } else {
          this.setState({ isEmailInvalid: true });
        }
        break;
      default:
        break;
    }
  }

  restartOrder() {
    const { unCompleteAllSteps, flushCart, setFirstStep } = this.props;

    unCompleteAllSteps();

    this.closeRestartModal();

    // setTimeout is needed because of the modal animation (150ms)
    setTimeout(() => {
      flushCart();
      setFirstStep();
    }, 150);
  }

  openRestartModal() {
    this.setState({ isRestartModalOpen: true });
  }

  closeRestartModal() {
    this.setState({ isRestartModalOpen: false });
  }

  backToEditingClick() {
    const { setFirstStep } = this.props;
    setFirstStep();
  }

  sendRequestClick() {
    const {
      nameInput,
      emailInput,
      phoneInput,
      commentInput,
      acceptCheckbox,
    } = this;
    const {
      isNameInvalid,
      isEmailInvalid,
      isAcceptCheckboxInvalid,
    } = this.state;

    // Using local states as global states are updated asyncronously
    let iNI = isNameInvalid;
    let iEI = isEmailInvalid;
    let iCI = isAcceptCheckboxInvalid;

    // Change inputs to valid is unnecessary as they are updated with this.handleInputChange()
    if (nameInput.current.value.length === 0) {
      iNI = true;
      this.setState({ isNameInvalid: iNI });
    }

    if (emailInput.current.value.length === 0) {
      iEI = true;
      this.setState({ isEmailInvalid: iEI });
    }

    // Change checkbox to valid if checked is needed
    if (!acceptCheckbox.current.checked) {
      iCI = true;
    } else {
      iCI = false;
    }

    this.setState({ isAcceptCheckboxInvalid: iCI });

    if (!iNI && !iEI && !iCI) {
      const { sendOffer, cart } = this.props;
      const { installationCheckbox } = this;

      const {
        boatWeight,
        boatType,
        motorType,
        motor,
        battery,
        charger,
        accessory,
        total,
        totalDiscountedPrice,
      } = cart;

      const motorPrice = motor.discountedPrice || motor.price;
      const batteryPrice = battery.discountedPrice || battery.price;
      const chargerPrice = charger.discountedPrice || charger.price;
      const totalPrice =
        total > totalDiscountedPrice ? totalDiscountedPrice : total;

      const formData = new FormData();
      formData.append('boat_weight', boatWeight);
      formData.append('boat_type', boatType);
      formData.append('motor_type', motorType);
      formData.append('article_number', motor.articleNumber);
      formData.append('motor_desc', motor.name);
      formData.append('motor_price', motorPrice);
      formData.append('batt_desc', battery.name);
      formData.append('batt_price', batteryPrice);
      formData.append('charger_desc', charger.name);
      formData.append('charger_price', chargerPrice);
      formData.append('options', [accessory.name]);
      formData.append('total_price', totalPrice);
      formData.append('newsletter', installationCheckbox.current.checked);
      formData.append('name', nameInput.current.value);
      formData.append('email', emailInput.current.value);
      formData.append('phone', phoneInput.current.value);
      formData.append('comment', commentInput.current.value);

      sendOffer(formData);
    }
  }

  openPrivacyPolicyModal() {
    this.setState({ isPrivacyPolicyModalOpen: true });
  }

  closePrivacyPolicyModal() {
    this.setState({ isPrivacyPolicyModalOpen: false });
  }

  acceptPrivacyPolicy() {
    this.setState({ isAcceptCheckboxChecked: true });
    this.closePrivacyPolicyModal();
  }

  render() {
    const {
      handleInputChange,
      onAcceptCheckboxChange,
      backToEditingClick,
      sendRequestClick,
      restartOrder,
      openRestartModal,
      openPrivacyPolicyModal,
      closePrivacyPolicyModal,
      closeRestartModal,
      acceptPrivacyPolicy,
    } = this;

    const {
      showTables,
      doesInclude,
      isEmailInvalid,
      isNameInvalid,
      isAcceptCheckboxChecked,
      isAcceptCheckboxInvalid,
      isRestartModalOpen,
      isPrivacyPolicyModalOpen,
    } = this.state;

    const {
      cart,
      isThankYouModalOpen,
      removeAccessory,
      removeBattery,
      removeCharger,
      removeMotor,
      tyTitle,
      termsAndConditions,
      privacyPolicy,
      tyDesc,
    } = this.props;

    const {
      installationCheckbox,
      acceptCheckbox,
      nameInput,
      emailInput,
      phoneInput,
      commentInput,
    } = this;

    return (
      <PriceRequestView
        cart={cart}
        showTables={showTables}
        doesInclude={doesInclude}
        removeAccessory={removeAccessory}
        removeBattery={removeBattery}
        removeCharger={removeCharger}
        removeMotor={removeMotor}
        handleInputChange={handleInputChange}
        onAcceptCheckboxChange={onAcceptCheckboxChange}
        isEmailInvalid={isEmailInvalid}
        isNameInvalid={isNameInvalid}
        backToEditingClick={backToEditingClick}
        sendRequestClick={sendRequestClick}
        restartOrder={restartOrder}
        isRestartModalOpen={isRestartModalOpen}
        openRestartModal={openRestartModal}
        closeRestartModal={closeRestartModal}
        openPrivacyPolicyModal={openPrivacyPolicyModal}
        closePrivacyPolicyModal={closePrivacyPolicyModal}
        isThankYouModalOpen={isThankYouModalOpen}
        isPrivacyPolicyModalOpen={isPrivacyPolicyModalOpen}
        tyTitle={tyTitle}
        tyDesc={tyDesc}
        termsAndConditions={termsAndConditions}
        privacyPolicy={privacyPolicy}
        installationCheckbox={installationCheckbox}
        acceptCheckbox={acceptCheckbox}
        isAcceptCheckboxChecked={isAcceptCheckboxChecked}
        isAcceptCheckboxInvalid={isAcceptCheckboxInvalid}
        acceptPrivacyPolicy={acceptPrivacyPolicy}
        nameInput={nameInput}
        emailInput={emailInput}
        phoneInput={phoneInput}
        commentInput={commentInput}
      />
    );
  }
}

export default PriceRequest;
