import React, { Component } from 'react';

import { richText, img } from '../../models/cms';
import SlideshowView from './slideshow-view';

class Slideshow extends Component {
  static propTypes = {
    title: richText,
    bg: img,
  };

  render() {
    const { title, bg } = this.props;

    return <SlideshowView title={title} bg={bg} />;
  }
}

export default Slideshow;
