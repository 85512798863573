import React, { Component } from 'react';
import { Link } from 'react-scroll';
import { Button } from 'react-bootstrap';

import i18n from '../../i18n';

export default class FooterView extends Component {
  render() {
    return (
      <Link to="5" spy smooth duration={400} className="btn-floating-link">
        <Button variant="light" className="btn-floating">
          <img
            src={`${process.env.PUBLIC_URL}/assets/calc.svg`}
            alt=""
            className="d-md-block mr-3 mx-md-auto mb-2"
          />
          {i18n.t('floatingButton')}
        </Button>
      </Link>
    );
  }
}
