import ProductsApi from './products';
import EconomyModeApi from './economy-mode';
import UserdataApi from './userdata';
import SubscribeApi from './subscribe';
import OfferApi from './offer';

export default class Api {
  static request = (requestId, variables, token) => {
    switch (requestId) {
      case 'getProducts':
        return ProductsApi.list(variables, token);
      case 'economyMode':
        return EconomyModeApi.list(variables, token);
      case 'sendUserdata':
        return UserdataApi.sendUserdata(variables, token);
      case 'subscribe':
        return SubscribeApi.subscribe(variables, token);
      case 'sendOffer':
        return OfferApi.send(variables, token);
      default:
    }
    return null;
  };
}
