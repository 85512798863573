// import { createStore, applyMiddleware, compose } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  const logger = createLogger();
  middlewares.push(logger);
}

let persistor = null;
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

middlewares.push(() => (next) => (action) => {
  if (action.type === 'FLUSH') {
    persistor.flush();
  }

  return next(action);
});

export default (initialState = {}) => {
  const store = createStore(
    persistedReducer,
    initialState,
    // compose(
    applyMiddleware(...middlewares)
    //   // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
  );

  persistor = persistStore(store, {});

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
