import { connect } from 'react-redux';

import ChargerSelect from './charger';
import { addCharger, removeCharger } from '../../../reducers/cart';

const mapStateToProps = ({ cart, products }) => {
  return {
    motors: products && products.motors,
    chargers: products && products.chargers,
    cart,
  };
};

export default connect(mapStateToProps, { addCharger, removeCharger })(
  ChargerSelect
);
