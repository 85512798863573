import React, { Component } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

import config from '../../config';
import LanguageChange from '../language-change';
import i18n from '../../i18n';
import ContactModal from '../contact-modal';

export default class NavigationView extends Component {
  static propTypes = {
    isExpanded: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    toggleNavigation: PropTypes.func,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
  };

  render() {
    const {
      isExpanded,
      isModalOpen,
      toggleNavigation,
      openModal,
      closeModal,
    } = this.props;

    const { innerWidth: screenWidth } = window;
    const isMobileView = screenWidth < 600;

    return (
      <>
        <Navbar expand="lg" variant="light" expanded={isExpanded}>
          <div className="navbar-header">
            <Navbar.Brand href="#" className="mr-auto">
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo-text.svg`}
                width="280"
                className="d-none d-sm-inline-block align-middle"
                alt={i18n.t('site.title')}
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo-text.svg`}
                width="234"
                className="d-inline-block d-sm-none align-middle"
                alt={i18n.t('site.title')}
              />
            </Navbar.Brand>
            <Navbar.Toggle onClick={toggleNavigation}>
              <img
                src={`${config.IMAGES_ROOT_URL}/hamburger.svg`}
                alt="menu-icon"
              />
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse>
            <div className="navbar-close">
              <Button variant="link" onClick={toggleNavigation}>
                <img src={`${config.IMAGES_ROOT_URL}/x-white.svg`} alt="x" />
              </Button>
            </div>
            <Nav className="my-3 ml-lg-auto text-center">
              <Link to="5" smooth duration={400} className="nav-link">
                {i18n.t('navigation.requestOffer')}
              </Link>
              <Link to="2" smooth duration={400} className="nav-link">
                {i18n.t('navigation.about')}
              </Link>
              <Link to="3" smooth duration={400} className="nav-link">
                {i18n.t('navigation.system')}
              </Link>
              <Link
                to="4"
                smooth
                duration={400}
                offset={isMobileView ? -50 : -125}
                className="nav-link"
              >
                {i18n.t('navigation.package')}
              </Link>
              <Nav.Link onClick={openModal}>
                {i18n.t('navigation.contact')}
              </Nav.Link>
              <Navbar.Text>
                <div className="my-3 mt-4 my-lg-0 ml-lg-2 fs-14">
                  <LanguageChange />
                </div>
              </Navbar.Text>
              <Navbar.Text className="d-lg-none">
                <p className="mt-3 mb-4 fs-14">
                  <a href={`tel:${i18n.t('site.phoneNum')}`} className="mb-0">
                    {i18n.t('site.phoneNum')}
                  </a>
                </p>
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ContactModal isModalOpen={isModalOpen} closeModal={closeModal} />
      </>
    );
  }
}
