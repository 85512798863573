import { REQUEST } from '../request';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SEND_USERDATA_SUCCESS = 'SEND_USERDATA_SUCCESS';
export const SEND_USERDATA_FAILED = 'SEND_USERDATA_FAILED';

export const Types = {
  CLOSE_MODAL,
  SEND_USERDATA_SUCCESS,
  SEND_USERDATA_FAILED,
};

export const initialState = {
  isFetching: false,
  isModalOpen: false,
};

const userdata = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /SEND_USERDATA/.test(action.payload.action)) ||
          state.isFetching,
        isModalOpen: false,
      };
    case SEND_USERDATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isModalOpen: true,
      };
    case SEND_USERDATA_FAILED:
      return {
        ...state,
        isFetching: false,
        isModalOpen: false,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };

    default:
      return state;
  }
};

export const closeModal = () => ({
  type: Types.CLOSE_MODAL,
  payload: {},
});

export const Actions = {
  closeModal,
};

export default userdata;
