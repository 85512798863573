import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import AccessoryDetails from '../../product-modals/accessory-details';
import { accessories as accessoriesModel } from '../../../../models/products';

export default function AccessoryTable(props) {
  const {
    accessories,
    selectedAccessory,
    addAccessory,
    removeAccessory,
    onSelectClick,
  } = props;

  return (
    <TableContainer>
      <Table className="my-3">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="p-2">
              {i18n.t('priceRequest.accessory.name')}
            </TableCell>
            <TableCell className="p-2" />
            <TableCell className="p-2">
              {i18n.t('priceRequest.accessory.price')}
            </TableCell>
            <TableCell className="buttonCol" />
          </TableRow>
        </TableHead>
        <TableBody>
          {accessories &&
            accessories.map((item) => (
              <AccessoryDetails
                key={item.id}
                data={{ ...item }}
                selectedAccessory={selectedAccessory}
                addAccessory={addAccessory}
                removeAccessory={removeAccessory}
                onSelectClick={onSelectClick}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AccessoryTable.propTypes = {
  accessories: accessoriesModel,
  selectedAccessory: PropTypes.objectOf(PropTypes.any),
  addAccessory: PropTypes.func,
  removeAccessory: PropTypes.func,
  onSelectClick: PropTypes.func,
};
