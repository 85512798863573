import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { richText } from '../../models/cms';
import ThankYouModalView from './thank-you-modal-view';

class ThankYouModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    title: richText,
    desc: richText,
  };

  render() {
    const { isModalOpen, closeModal, title, desc } = this.props;

    return (
      <ThankYouModalView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        title={title}
        desc={desc}
      />
    );
  }
}

export default ThankYouModal;
