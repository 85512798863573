import React from 'react';

import PropTypes from 'prop-types';
import ListPriceView from './list-price-view';
import {
  accessory as accessoryModel,
  battery as batteryModel,
  charger as chargerModel,
  motor as motorModel,
} from '../../../models/products';

export default function ListPrice(props) {
  const {
    total,
    motor,
    battery,
    charger,
    accessory,
    totalDiscountedPrice,
    removeAccessory,
    removeMotor,
    removeCharger,
    removeBattery,
    setStep,
    activeStep,
    nextButtonRef,
    lang,
  } = props;

  return (
    <ListPriceView
      total={total}
      motor={motor}
      battery={battery}
      charger={charger}
      accessory={accessory}
      totalDiscountedPrice={totalDiscountedPrice}
      removeAccessory={removeAccessory}
      removeMotor={removeMotor}
      removeBattery={removeBattery}
      removeCharger={removeCharger}
      setStep={setStep}
      activeStep={activeStep}
      nextButtonRef={nextButtonRef}
      lang={lang}
    />
  );
}

ListPrice.propTypes = {
  removeCharger: PropTypes.func,
  removeBattery: PropTypes.func,
  removeMotor: PropTypes.func,
  removeAccessory: PropTypes.func,
  total: PropTypes.number,
  totalDiscountedPrice: PropTypes.number,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  lang: PropTypes.string,
  nextButtonRef: PropTypes.instanceOf(Object),
  motor: motorModel,
  battery: batteryModel,
  charger: chargerModel,
  accessory: accessoryModel,
};
