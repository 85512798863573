import React, { useState } from 'react';
import PropTypes from 'prop-types';

import config from '../../config';

const Image = ({ imageName, alt, fallbackImg, ...props }) => {
  const initialImage = imageName || fallbackImg || 'empty_image.svg';
  const [image, setImage] = useState(initialImage);

  const handleImageError = () => {
    setImage(fallbackImg || 'empty_image.svg');
  };

  return (
    <img
      src={`${config.IMAGES_ROOT_URL}/${image}`}
      onError={handleImageError}
      alt={alt}
      {...props}
    />
  );
};

export default Image;

Image.propTypes = {
  imageName: PropTypes.string,
  alt: PropTypes.string,
  fallbackImg: PropTypes.string,
};
