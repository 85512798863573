import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import i18n from '../../i18n';
import { richText } from '../../models/cms';

export default class ThankYouModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    title: richText,
    desc: richText,
  };

  render() {
    const { isModalOpen, closeModal, title, desc } = this.props;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="text-center px-5 pb-5">
          <div className="mb-3">{title && RichText.render(title)}</div>
          <div className="mb-4">{desc && RichText.render(desc)}</div>
          <Button variant="primary" onClick={closeModal} className="mt-1 mx-1">
            {i18n.t('thankYouModal.ok')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}
