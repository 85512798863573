import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubscribeModalView from './subscribe-modal-view';

class SubscribeModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    subscribe: PropTypes.func,
    closeThankYouModal: PropTypes.func,
    isThankYouModalOpen: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEmailInvalid: false,
      email: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitUserdata = this.submitUserdata.bind(this);
  }

  handleInputChange(e) {
    const inputValue = e.target.value;
    let re;

    switch (e.target.id) {
      case 'subscribeEmail': // Email validation
        re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(inputValue) || inputValue.length === 0) {
          this.setState({ isEmailInvalid: false, email: inputValue });
        } else {
          this.setState({ isEmailInvalid: true });
        }
        break;
      default:
        break;
    }
  }

  submitUserdata() {
    const { email, isEmailInvalid } = this.state;
    const emailLength = email.length;

    if (!isEmailInvalid && emailLength !== 0) {
      const { subscribe, closeModal } = this.props;

      closeModal();

      // Setting a timeout for a better Thank You modal render
      setTimeout(() => {
        subscribe(this.state);
      }, 150);
    }
  }

  closeContactModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      isModalOpen,
      closeModal,
      closeThankYouModal,
      isThankYouModalOpen,
    } = this.props;
    const { isEmailInvalid, email } = this.state;

    const { handleInputChange, submitUserdata } = this;

    return (
      <SubscribeModalView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isEmailInvalid={isEmailInvalid}
        email={email}
        isThankYouModalOpen={isThankYouModalOpen}
        handleInputChange={handleInputChange}
        closeThankYouModal={closeThankYouModal}
        submitUserdata={submitUserdata}
      />
    );
  }
}

export default SubscribeModal;
