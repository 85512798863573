import React, { Component } from 'react';

import { img, checkList } from '../../models/cms';
import ChecksView from './checks-view';

class Checks extends Component {
  static propTypes = {
    image: img,
    motor: checkList,
    battery: checkList,
  };

  render() {
    const { image, motor, battery } = this.props;

    return <ChecksView image={image} motor={motor} battery={battery} />;
  }
}

export default Checks;
