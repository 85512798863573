import { connect } from 'react-redux';

import AccessorySelect from './accessory';
import { addAccessory, removeAccessory } from '../../../reducers/cart';

const mapStateToProps = ({ products }) => {
  return { accessories: products && products.accessories };
};

export default connect(mapStateToProps, { addAccessory, removeAccessory })(
  AccessorySelect
);
