import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import LanguageChange from '../language-change';
import SubscribeModal from '../subscribe-modal';
import ContactModal from '../contact-modal';

export default class FooterView extends Component {
  static propTypes = {
    isSubscribeModalOpen: PropTypes.bool,
    openSubscribeModal: PropTypes.func,
    closeSubscribeModal: PropTypes.func,
    isContactModalOpen: PropTypes.bool,
    openContactModal: PropTypes.func,
    closeContactModal: PropTypes.func,
  };

  render() {
    const {
      isSubscribeModalOpen,
      openSubscribeModal,
      closeSubscribeModal,
      isContactModalOpen,
      openContactModal,
      closeContactModal,
    } = this.props;

    return (
      <>
        <Container fluid className="footer bg-dark text-white">
          <Container>
            <Row>
              <Col xs={12} lg={4}>
                <p className="copyright text-uppercase">
                  <strong>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/logo-text-light.svg`}
                      width="280"
                      className="d-none d-sm-inline-block align-top"
                      alt={i18n.t('site.title')}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/logo-text-light.svg`}
                      width="230"
                      className="d-inline-block d-sm-none align-top"
                      alt={i18n.t('site.title')}
                    />
                  </strong>
                </p>
                <p>
                  Copyright &copy; {i18n.t('site.title')} –{' '}
                  {i18n.t('footer.copyright')}
                </p>
              </Col>
              <Col xs={8} lg={2}>
                <p
                  className="contact-us title text-uppercase"
                  onClick={openContactModal}
                >
                  <strong>{i18n.t('footer.contactUs')}</strong>
                </p>
                <p className="phone">
                  <strong>
                    <a href={`tel:${i18n.t('site.phoneNum')}`}>
                      {i18n.t('site.phoneNum')}
                    </a>
                  </strong>
                </p>
              </Col>
              <Col xs={4} lg={2}>
                <LanguageChange />
              </Col>
              <Col
                xs={12}
                lg={4}
                className="subscribe-btn text-center text-lg-right"
              >
              </Col>
            </Row>
          </Container>
        </Container>
        <SubscribeModal
          isModalOpen={isSubscribeModalOpen}
          closeModal={closeSubscribeModal}
        />
        <ContactModal
          isModalOpen={isContactModalOpen}
          closeModal={closeContactModal}
        />
      </>
    );
  }
}
