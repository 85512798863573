import React, { Component } from 'react';

import { richText } from '../../models/cms';
import AboutView from './about-view';

class About extends Component {
  static propTypes = {
    title: richText,
    goldenDesc: richText,
    desc: richText,
  };

  render() {
    const { title, goldenDesc, desc } = this.props;

    return <AboutView title={title} goldenDesc={goldenDesc} desc={desc} />;
  }
}

export default About;
