import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RestartOrderModalView from './restart-order-modal-view';

class RestartOrderModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    restartOrder: PropTypes.func,
  };

  render() {
    const { isModalOpen, closeModal, restartOrder } = this.props;

    return (
      <RestartOrderModalView
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        restartOrder={restartOrder}
      />
    );
  }
}

export default RestartOrderModal;
