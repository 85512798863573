import { REQUEST, REHYDRATE } from '../request';

const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const Types = {
  GET_PRODUCTS_SUCCESS,
  REQUEST,
};

export const initialState = {
  isFetching: false,
  products: {
    batteries: [],
    chargers: [],
    motors: [],
    accessories: [],
  }, // data && data.default,
  errors: null,
};

const products = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /PRODUCT/.test(action.payload.action)) ||
          state.isFetching,
        errors:
          action.payload && /PRODUCT/.test(action.payload.action)
            ? null
            : state.errors,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...(action.payload || []),
        errors: null,
      };

    case REHYDRATE:
      return {
        ...((action.payload && action.payload.products) || initialState),
        isFetching: false,
      };

    case 'FLUSH':
      return initialState;
    default:
      return state;
  }
};

export const Actions = {};

export default products;
