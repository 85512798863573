import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AccessoryView from './accessory-view';
import { accessories as accessoriesModel } from '../../../models/products';

export default function AccessorySelect(props) {
  // load all accessories
  const selectedAccessory = useSelector((state) => state.cart.accessory);
  const {
    accessories,
    addAccessory,
    removeAccessory,
    setStep,
    activeStep,
    onSelectClick,
    bottomSectionRef,
  } = props;

  return (
    <AccessoryView
      accessories={accessories}
      addAccessory={addAccessory}
      removeAccessory={removeAccessory}
      selectedAccessory={selectedAccessory}
      setStep={setStep}
      activeStep={activeStep}
      onSelectClick={onSelectClick}
      bottomSectionRef={bottomSectionRef}
    />
  );
}

AccessorySelect.propTypes = {
  accessories: accessoriesModel,
  addAccessory: PropTypes.func,
  removeAccessory: PropTypes.func,
  setStep: PropTypes.func,
  activeStep: PropTypes.number,
  onSelectClick: PropTypes.func,
  bottomSectionRef: PropTypes.instanceOf(Object),
};
