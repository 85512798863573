import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ThankYouModalView from './thank-you-view';

class ThankYouModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  render() {
    const { isModalOpen, closeModal } = this.props;

    return (
      <ThankYouModalView isModalOpen={isModalOpen} closeModal={closeModal} />
    );
  }
}

export default ThankYouModal;
