import { combineReducers } from 'redux';
import app from './app';
import notifications from './notifications';
import products from './products';
import economyMode from './economy-mode';
import cart from './cart';
import offer from './offer';
import subscribe from './subscribe';
import userdata from './userdata';

export default combineReducers({
  app,
  notifications,
  products,
  economyMode,
  cart,
  offer,
  subscribe,
  userdata,
});
