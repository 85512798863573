import React, { Component, createRef } from 'react';
import { scroller } from 'react-scroll';

import i18n from '../../i18n';
import { cartItems as cartModel } from '../../models/products';
import { richText } from '../../models/cms';

import BoatInfo from './boatinfo';
import MotorSelect from './motor';
import BatterySelect from './battery';
import ChargerSelect from './charger';
import AccessorySelect from './accessory';
import PriceRequest from './price-request';
import StepperView from './stepper-view';

export default class Stepper extends Component {
  static propTypes = {
    cart: cartModel,
    privacyPolicy: richText,
    termsAndConditions: richText,
    tyTitle: richText,
    tyDesc: richText,
  };

  static getSteps() {
    return [
      i18n.t('priceRequest.titles.info'),
      i18n.t('priceRequest.titles.motor'),
      i18n.t('priceRequest.titles.battery'),
      i18n.t('priceRequest.titles.charger'),
      i18n.t('priceRequest.titles.accessory'),
      i18n.t('priceRequest.titles.priceRequest'),
    ];
  }

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      completed: new Set(),
      bottomSectionRef: createRef(),
    };

    this.getStepContent = this.getStepContent.bind(this);
    this.setStep = this.setStep.bind(this);
    this.completeStep = this.completeStep.bind(this);
    this.unCompleteStep = this.unCompleteStep.bind(this);
    this.unCompleteAllSteps = this.unCompleteAllSteps.bind(this);
    this.isStepComplete = this.isStepComplete.bind(this);
    this.handeSelectClick = this.handeSelectClick.bind(this);
  }

  getStepContent(step) {
    const { activeStep, bottomSectionRef } = this.state;
    const { privacyPolicy, termsAndConditions, tyTitle, tyDesc } = this.props;

    switch (step) {
      case 0:
        return <BoatInfo activeStep={activeStep} setStep={this.completeStep} />;
      case 1:
        return (
          <MotorSelect
            activeStep={activeStep}
            setStep={this.completeStep}
            onSelectClick={this.handeSelectClick}
            bottomSectionRef={bottomSectionRef}
          />
        );
      case 2:
        return (
          <BatterySelect
            activeStep={activeStep}
            setStep={this.completeStep}
            onSelectClick={this.handeSelectClick}
            bottomSectionRef={bottomSectionRef}
          />
        );
      case 3:
        return (
          <ChargerSelect
            activeStep={activeStep}
            setStep={this.completeStep}
            onSelectClick={this.handeSelectClick}
            bottomSectionRef={bottomSectionRef}
          />
        );
      case 4:
        return (
          <AccessorySelect
            activeStep={activeStep}
            setStep={this.completeStep}
            onSelectClick={this.handeSelectClick}
            bottomSectionRef={bottomSectionRef}
          />
        );
      case 5:
        return (
          <PriceRequest
            setFirstStep={() => this.setStep(0)}
            unCompleteAllSteps={this.unCompleteAllSteps}
            privacyPolicy={privacyPolicy}
            termsAndConditions={termsAndConditions}
            tyTitle={tyTitle}
            tyDesc={tyDesc}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  /**
   * Navigates to the chosen step, called if the user clicks on a Step button
   * @param {*} step The new step index to be set.
   */
  setStep(step) {
    const { activeStep } = this.state;
    const { cart } = this.props;

    switch (activeStep) {
      case 0:
        if (cart.boatType && cart.motorType && cart.boatWeight !== '0') {
          this.completeStep(step);
        }
        break;
      case 1:
        if (Object.entries(cart.motor).length > 0) {
          this.completeStep(step);
        } else {
          this.unCompleteStep(step);
        }
        break;
      case 2:
        if (Object.entries(cart.battery).length > 0) {
          this.completeStep(step);
        } else {
          this.unCompleteStep(step);
        }
        break;
      case 3:
        if (Object.entries(cart.charger).length > 0) {
          this.completeStep(step);
        } else {
          this.unCompleteStep(step);
        }
        break;
      case 4:
        if (Object.entries(cart.accessory).length > 0) {
          this.completeStep(step);
        } else {
          this.unCompleteStep(step);
        }
        break;
      case 5:
        this.setState({ activeStep: step });
        break;
      default:
        break;
    }
  }

  handeSelectClick() {
    const { bottomSectionRef } = this.state;
    bottomSectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  // @TODO @dh: check linting error - Expected 'this' to be used by class method 'scrollToStepper'  class-methods-use-this
  scrollToStepper() {
    scroller.scrollTo('5', {
      duration: 250,
      smooth: true,
    });
  }

  /**
   * Shows the next step and completes the step.
   */
  completeStep(step) {
    const { activeStep, completed } = this.state;

    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);

    // Check for input parameter (used in stepper click)
    let nextStep = activeStep + 1;
    if (step || step === 0) nextStep = step;

    this.setState({ completed: newCompleted, activeStep: nextStep });

    this.scrollToStepper();
  }

  unCompleteStep(step) {
    const { activeStep, completed } = this.state;

    const newCompleted = new Set(completed);
    newCompleted.delete(activeStep);

    // Check for input parameter (used in stepper click)
    let nextStep = activeStep + 1;
    if (step || step === 0) nextStep = step;

    this.setState({ completed: newCompleted, activeStep: nextStep });

    this.scrollToStepper();
  }

  unCompleteAllSteps() {
    const set = new Set();
    this.setState({ completed: set });

    this.scrollToStepper();
  }

  isStepComplete(step) {
    const { completed } = this.state;
    const { cart } = this.props;
    const set = new Set(completed);
    const productSteps = [
      { step: 1, item: cart.motor },
      { step: 2, item: cart.battery },
      { step: 3, item: cart.charger },
      { step: 4, item: cart.accessory },
    ];

    // @TODO
    // eslint-disable-next-line
    productSteps.map((i) => {
      if (step === i.step) {
        if (i.item.id && !set.has(i.step)) {
          set.add(i.step);
        } else if (!i.item.id && set.has(i.step)) {
          set.delete(i.step);
        }
      }
    });

    // checks if the step is completed
    return set.has(step);
  }

  render() {
    const { activeStep } = this.state;

    return (
      <StepperView
        activeStep={activeStep}
        steps={Stepper.getSteps()}
        setStep={this.setStep}
        getStepContent={this.getStepContent}
        completeStep={this.completeStep}
        isStepComplete={this.isStepComplete}
      />
    );
  }
}
