import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';

import { richText } from '../../models/cms';

export default class AboutView extends Component {
  static propTypes = {
    title: richText,
    goldenDesc: richText,
    desc: richText,
  };

  render() {
    const { title, goldenDesc, desc } = this.props;

    return (
      <Container className="about py-5" name="2">
        <div className="text-center mb-5">
          {title && RichText.render(title)}
        </div>
        <div className="text-golden mb-4">
          {goldenDesc && RichText.render(goldenDesc)}
        </div>
        {desc && RichText.render(desc)}
      </Container>
    );
  }
}
