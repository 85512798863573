import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';

import { img, checkList } from '../../models/cms';
import i18n from '../../i18n';

export default class ChecksView extends Component {
  static propTypes = {
    image: img,
    motor: checkList,
    battery: checkList,
  };

  render() {
    const { image, motor, battery } = this.props;

    return (
      <Container name="4">
        <Row>
          <Col xs={12} md={6}>
            <img
              src={image && image.url}
              alt={image && image.alt}
              className="mb-5 mw-100"
            />
          </Col>
          <Col xs={12} md={6} className="check-texts">
            <h5>
              <strong>{i18n.t('checks.forEveryMotor')}</strong>
            </h5>
            {motor &&
              motor.map((x, index) => (
                /* eslint-disable react/no-array-index-key */
                <div key={index} className="my-4 included-item-container">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/check.svg`}
                    alt="✔️"
                    className="d-inline mr-3"
                  />
                  <div className="d-inline child-p-d-inline">
                    {RichText.render(x.text)}
                  </div>
                </div>
                /* eslint-enable react/no-array-index-key */
              ))}
            <h5>
              <strong>{i18n.t('checks.forEveryBattery')}</strong>
            </h5>
            {battery &&
              battery.map((x, index) => (
                /* eslint-disable react/no-array-index-key */
                <div key={index} className="my-4 included-item-container">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/check.svg`}
                    alt="✔️"
                    className="d-inline mr-3"
                  />
                  <div className="d-inline child-p-d-inline">
                    {RichText.render(x.text)}
                  </div>
                </div>
                /* eslint-enable react/no-array-index-key */
              ))}
          </Col>
        </Row>
      </Container>
    );
  }
}
