// Base Request
export const REQUEST = 'REQUEST';
export const REHYDRATE = 'persist/REHYDRATE';

// Product Requests
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SEND_OFFER = 'SEND_OFFER';

// Contact Us Request
export const SEND_USERDATA = 'SEND_USERDATA';

// Newsletter Subscription Request
export const SUBSCRIBE = 'SUBSCRIBE';

// Economy mode request
export const ECOMODE = 'ECOMODE';

export const Types = {
  REQUEST,
  REHYDRATE,
};

export const ActionTypes = {
  GET_PRODUCTS,
  SEND_OFFER,
  SEND_USERDATA,
  SUBSCRIBE,
  ECOMODE,
};

export const getProducts = (lang = 'en') => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.GET_PRODUCTS,
    requestId: 'getProducts',
    variables: {
      lang,
    },
  },
});

export const sendUserdata = (userdata) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.SEND_USERDATA,
    requestId: 'sendUserdata',
    variables: {
      userdata,
    },
  },
});

export const subscribe = (userdata) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.SUBSCRIBE,
    requestId: 'subscribe',
    variables: {
      userdata,
    },
  },
});

export const sendOffer = (offer) => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.SEND_OFFER,
    requestId: 'sendOffer',
    variables: {
      offer,
    },
  },
});

export const ecoMode = () => ({
  type: 'REQUEST',
  payload: {
    action: ActionTypes.ECOMODE,
    requestId: 'economyMode',
    variables: {},
  },
});

export const Actions = {
  getProducts,
  sendUserdata,
  subscribe,
  sendOffer,
  ecoMode,
};
