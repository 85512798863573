import { connect } from 'react-redux';

import BoatInfo from './boatinfo';
import {
  addMotor,
  setBoatType,
  setMotorType,
  setBoatWeight,
} from '../../../reducers/cart';

export default connect((state) => state, {
  addMotor,
  setBoatType,
  setMotorType,
  setBoatWeight,
})(BoatInfo);
