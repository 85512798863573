import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../i18n';

import { Types, Actions, MessageTypes } from '../../reducers/notifications';

export const displayAlert = ({ title, message, type }) => {
  let cleanMessage = (title && `${title}: `) || '';
  cleanMessage += message || '';

  switch (type) {
    case MessageTypes.Error:
      toast.error(cleanMessage);
      break;
    case MessageTypes.Success:
      toast.success(cleanMessage);
      break;
    case MessageTypes.Warning:
      toast.warn(cleanMessage);
      break;
    default:
      toast.info(cleanMessage);
  }
};

export function* showNotification(action) {
  if (!action.payload) return;

  const msg = {
    title: action.payload.title || '',
    type: action.payload.type || MessageTypes.Info,
    message:
      (action.payload.message &&
        (i18n.t(`backendResponse.${action.payload.message}`) ||
          action.payload.message)) ||
      'Something went wrong. Please ensure you have internet connection and try it later.',
  };

  yield call(displayAlert, msg);
  yield put(Actions.hideNotification(action.uid));
}

export default function* () {
  yield takeLatest(Types.SHOW_NOTIFICATION, showNotification);
}
