import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LanguageChangeView from './language-change-view';

class LanguageChange extends Component {
  static propTypes = {
    changeLanguage: PropTypes.func,
    lang: PropTypes.string,
  };

  langChange = (e) => {
    const { changeLanguage } = this.props;

    changeLanguage(e.target.innerText === 'EN' ? 'en-gb' : 'hu');
  };

  render() {
    const { lang } = this.props;

    return <LanguageChangeView langChange={this.langChange} lang={lang} />;
  }
}

export default LanguageChange;
