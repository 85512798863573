import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-scroll';

import { richText, iconText } from '../../models/cms';
import i18n from '../../i18n';

export default class JumbotronView extends Component {
  static propTypes = {
    title: richText,
    desc: richText,
    steps: iconText,
  };

  render() {
    const { title, desc, steps } = this.props;

    return (
      <Jumbotron fluid className="my-5" name="3">
        <Container className="text-center p-sm-3 p-md-5">
          <Col sm={12} md={9} lg={12} className="mx-auto mb-5">
            {title && RichText.render(title)}
            <div className="text-golden mw-700px my-5 mx-auto">
              {desc && RichText.render(desc)}
            </div>
            <Row>
              {steps &&
                steps.map((x, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <Col
                    key={index}
                    xs={12}
                    sm={4}
                    lg={3}
                    className="mx-auto jumbo-arrow system-step"
                  >
                    <Row className="my-2 align-items-center">
                      <Col xs={6} sm={12} className="step">
                        <img src={x.icon.url} alt={x.icon.alt} />
                      </Col>
                      <Col
                        xs={6}
                        sm={12}
                        className={`text-${index + 1} text-left text-sm-center`}
                      >
                        {RichText.render(x.text)}
                      </Col>
                    </Row>
                  </Col>
                  /* eslint-enable react/no-array-index-key */
                ))}
            </Row>
          </Col>
          <Button variant="primary">
            <Link to="5" smooth duration={400}>
              {i18n.t('jumbotron.selectButton')}
            </Link>
          </Button>
        </Container>
      </Jumbotron>
    );
  }
}
