import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import BatteryDetails from '../../product-modals/battery-details';
import { batteries as batteriesModel } from '../../../../models/products';

export default function BatteryTable(props) {
  const {
    batteries,
    selectedBattery,
    addBattery,
    removeBattery,
    onSelectClick,
  } = props;

  return (
    <>
      <TableContainer>
        <Table className="my-3">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className="p-2">
                {i18n.t('priceRequest.battery.type')}
              </TableCell>
              <TableCell className="p-2">
                {i18n.t('priceRequest.battery.capacity')}
              </TableCell>
              <TableCell className="p-2">
                {i18n.t('priceRequest.battery.runningTime')}
              </TableCell>
              <TableCell className="p-2">
                {i18n.t('priceRequest.battery.price')}
              </TableCell>
              <TableCell className="buttonCol" />
            </TableRow>
          </TableHead>
          <TableBody>
            {batteries &&
              batteries.map((item) => (
                <BatteryDetails
                  key={item && item.id}
                  data={{ ...item }}
                  selectedBattery={selectedBattery}
                  addBattery={addBattery}
                  removeBattery={removeBattery}
                  onSelectClick={onSelectClick}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

BatteryTable.propTypes = {
  batteries: batteriesModel,
  selectedBattery: PropTypes.objectOf(PropTypes.any),
  addBattery: PropTypes.func,
  removeBattery: PropTypes.func,
  onSelectClick: PropTypes.func,
};
