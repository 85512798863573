import React, { Component } from 'react';

import FloatingButtonView from './floating-button-view';

class FloatingButton extends Component {
  render() {
    return <FloatingButtonView />;
  }
}

export default FloatingButton;
