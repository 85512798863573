import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import MotorDetails from '../../product-modals/motor-details';
import {
  motors as motorsModel,
  cartItems as cartModel,
} from '../../../../models/products';
import i18n from '../../../../i18n';

export default function MotorTable(props) {
  const {
    cart,
    motors,
    selectedMotor,
    addMotor,
    removeMotor,
    onSelectClick,
  } = props;

  return (
    <TableContainer>
      <Table className="my-3">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.engine')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.voltage')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.continuousCurrent')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.maxCurrent')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.maxWeight')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.motor.listPrice')}
            </TableCell>
            <TableCell className="buttonCol" />
          </TableRow>
        </TableHead>
        <TableBody>
          {motors &&
            motors.map((item) => (
              <MotorDetails
                key={item && item.id}
                data={{ ...item }}
                cart={cart}
                selectedMotor={selectedMotor}
                addMotor={addMotor}
                removeMotor={removeMotor}
                onSelectClick={onSelectClick}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MotorTable.propTypes = {
  motors: motorsModel,
  selectedMotor: PropTypes.objectOf(PropTypes.any),
  addMotor: PropTypes.func,
  cart: cartModel,
  removeMotor: PropTypes.func,
  onSelectClick: PropTypes.func,
};
