import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class LanguageChangeView extends Component {
  static propTypes = {
    langChange: PropTypes.func,
    lang: PropTypes.string,
  };

  render() {
    const { langChange, lang } = this.props;

    return (
      <>
        <Button
          onClick={langChange}
          variant="link"
          className={`btn-sm mx-1 p-0${lang === 'en-gb' ? ' active' : ''}`}
        >
          EN
        </Button>
        <Button
          onClick={langChange}
          variant="link"
          className={`btn-sm mx-1 p-0${lang === 'hu' ? ' active' : ''}`}
        >
          HU
        </Button>
      </>
    );
  }
}

export default LanguageChangeView;
