import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import i18n from '../../i18n';
import { richText } from '../../models/cms';

export default class PrivacyPolicyModalView extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    acceptBtnClick: PropTypes.func,
    termsAndConditions: richText,
    privacyPolicy: richText,
  };

  render() {
    const {
      isModalOpen,
      closeModal,
      acceptBtnClick,
      termsAndConditions,
      privacyPolicy,
    } = this.props;

    return (
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton />
        <Modal.Body className="text-center px-5 pb-5">
          <div className="mb-4">
            <h3 className="mb-3">
              <strong>{i18n.t('privacyPolicyModal.termsAndConditions')}</strong>
            </h3>
            <div>
              {termsAndConditions && RichText.render(termsAndConditions)}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="mb-3">
              <strong>{i18n.t('privacyPolicyModal.privacyPolicy')}</strong>
            </h3>
            <div>{privacyPolicy && RichText.render(privacyPolicy)}</div>
          </div>
          <Button
            variant="primary"
            onClick={acceptBtnClick}
            className="mt-1 mx-1"
          >
            {i18n.t('privacyPolicyModal.accept')}
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}
