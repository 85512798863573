const ADD_MOTOR = 'ADD_MOTOR';
const ADD_BATTERY = 'ADD_BATTERY';
const ADD_CHARGER = 'ADD_CHARGER';
const ADD_ACCESSORY = 'ADD_ACCESSORY';
const SET_BOATTYPE = 'SET_BOATTYPE';
const SET_MOTORTYPE = 'SET_MOTORTYPE';
const SET_BOATWEIGHT = 'SET_BOATWEIGHT';
const REMOVE_ACCESSORY = 'REMOVE_ACCESSORY';
const REMOVE_MOTOR = 'REMOVE_MOTOR';
const REMOVE_CHARGER = 'REMOVE_CHARGER';
const REMOVE_BATTERY = 'REMOVE_BATTERY';
const FLUSH_CART = 'FLUSH_CART';

export const Types = {
  ADD_MOTOR,
  ADD_BATTERY,
  ADD_CHARGER,
  ADD_ACCESSORY,
  SET_BOATTYPE, // boatType ? sailing : motor
  SET_MOTORTYPE, // motorType ? inner : outer
  SET_BOATWEIGHT,
  REMOVE_ACCESSORY,
  REMOVE_MOTOR,
  REMOVE_CHARGER,
  REMOVE_BATTERY,
  FLUSH_CART,
};

export const initialState = {
  boatType: null,
  motorType: null,
  boatWeight: '0.3',
  motor: {},
  battery: {},
  charger: {},
  accessory: {},
  total: 0,
  totalDiscountedPrice: 0,
};

const cart = (state = initialState, action) => {
  let prevPrice = 0;
  let prevDiscountedPrice = 0;

  if (!action) return state;
  const { type, payload } = action;

  switch (type) {
    case ADD_BATTERY:
      prevPrice = (state && state.battery && state.battery.price) || 0;
      prevDiscountedPrice =
        (state && state.battery && state.battery.discountedPrice) || prevPrice;

      return {
        ...state,
        battery: {
          id: payload && payload.id,
          name: payload && payload.name,
          price: payload && payload.price,
          imageAddress: payload && payload.imageAddress,
          imageAlt: payload && payload.imageAlt,
          discountedPrice: payload && payload.discountedPrice,
        },
        total: state.total - prevPrice + ((payload && payload.price) || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          prevDiscountedPrice +
          (payload.discountedPrice || payload.price || 0),
      };
    case ADD_MOTOR:
      prevPrice = (state && state.motor && state.motor.price) || 0;
      prevDiscountedPrice =
        (state && state.motor && state.motor.discountedPrice) || prevPrice;

      return {
        ...state,
        motor: {
          id: payload && payload.id,
          name: payload && payload.name,
          price: payload && payload.price,
          imageAddress: payload && payload.imageAddress,
          imageAlt: payload && payload.imageAlt,
          articleNumber: payload && payload.articleNumber,
          discountedPrice: payload && payload.discountedPrice,
        },
        battery: initialState.battery,
        charger: initialState.charger,
        total: state.total - prevPrice + ((payload && payload.price) || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          prevDiscountedPrice +
          (payload.discountedPrice || payload.price || 0),
      };
    case ADD_CHARGER:
      prevPrice = (state && state.charger && state.charger.price) || 0;
      prevDiscountedPrice =
        (state && state.charger && state.charger.discountedPrice) || prevPrice;

      return {
        ...state,
        charger: {
          id: payload && payload.id,
          name: payload && payload.name,
          price: payload && payload.price,
          imageAddress: payload && payload.imageAddress,
          imageAlt: payload && payload.imageAlt,
          discountedPrice: payload && payload.discountedPrice,
        },
        total: state.total - prevPrice + ((payload && payload.price) || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          prevDiscountedPrice +
          (payload.discountedPrice || payload.price || 0),
      };
    case ADD_ACCESSORY:
      prevPrice = (state && state.accessory && state.accessory.price) || 0;
      prevDiscountedPrice =
        (state && state.accessory && state.accessory.discountedPrice) ||
        prevPrice;

      return {
        ...state,
        accessory: {
          id: payload && payload.id,
          name: payload && payload.name,
          price: payload && payload.price,
          imageAddress: payload && payload.imageAddress,
          imageAlt: payload && payload.imageAlt,
          discountedPrice: payload && payload.discountedPrice,
        },
        total: state.total - prevPrice + ((payload && payload.price) || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          prevDiscountedPrice +
          (payload.discountedPrice || payload.price || 0),
      };
    case REMOVE_MOTOR:
      return {
        ...state,
        motor: initialState.motor,
        total:
          state.total -
          payload.price -
          (state.battery?.price || 0) -
          (state.charger?.price || 0) -
          (state.accessory?.price || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          (state.motor?.discountedPrice || state.motor?.price || 0) -
          (state.battery?.discountedPrice || state.battery?.price || 0) -
          (state.charger?.discountedPrice || state.charger?.price || 0) -
          (state.accessory?.discountedPrice || state.accessory?.price || 0),
        battery: initialState.battery,
        charger: initialState.charger,
        accessory: initialState.accessory,
      };
    case REMOVE_BATTERY:
      return {
        ...state,
        battery: initialState.battery,
        total:
          state.total -
          payload.price -
          (state.charger?.price || 0) -
          (state.accessory?.price || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          (state.battery?.discountedPrice || state.battery?.price || 0) -
          (state.charger?.discountedPrice || state.charger?.price || 0) -
          (state.accessory?.discountedPrice || state.accessory?.price || 0),
        charger: initialState.charger,
        accessory: initialState.accessory,
      };
    case REMOVE_CHARGER:
      return {
        ...state,
        charger: initialState.charger,
        total: state.total - payload.price - (state.accessory?.price || 0),
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          (state.charger?.discountedPrice || state.charger?.price || 0) -
          (state.accessory?.discountedPrice || state.accessory?.price || 0),
        accessory: initialState.accessory,
      };
    case REMOVE_ACCESSORY:
      return {
        ...state,
        accessory: initialState.accessory,
        total: state.total - payload.price,
        totalDiscountedPrice:
          state.totalDiscountedPrice -
          (state.accessory?.discountedPrice || state.accessory?.price || 0),
      };
    case SET_BOATTYPE:
      return {
        ...state,
        boatType: payload && payload.boatType,
      };
    case SET_MOTORTYPE:
      return {
        ...state,
        motorType: payload && payload.motorType,
      };
    case SET_BOATWEIGHT:
      return {
        ...state,
        boatWeight: payload && payload.boatWeight,
      };
    case FLUSH_CART:
      return initialState;
    default:
      return state;
  }
};

export const addMotor = (
  id,
  name,
  price,
  imageAddress,
  imageAlt,
  articleNumber,
  discountedPrice
) => ({
  type: Types.ADD_MOTOR,
  payload: {
    id,
    name,
    price,
    imageAddress,
    imageAlt,
    articleNumber,
    discountedPrice,
  },
});

export const addBattery = (
  id,
  name,
  price,
  imageAddress,
  imageAlt,
  discountedPrice
) => ({
  type: Types.ADD_BATTERY,
  payload: {
    id,
    name,
    price,
    imageAddress,
    imageAlt,
    discountedPrice,
  },
});

export const addCharger = (
  id,
  name,
  price,
  imageAddress,
  imageAlt,
  discountedPrice
) => ({
  type: Types.ADD_CHARGER,
  payload: {
    id,
    name,
    price,
    imageAddress,
    imageAlt,
    discountedPrice,
  },
});

export const addAccessory = (
  id,
  name,
  price,
  imageAddress,
  imageAlt,
  discountedPrice
) => ({
  type: Types.ADD_ACCESSORY,
  payload: {
    id,
    name,
    price,
    imageAddress,
    imageAlt,
    discountedPrice,
  },
});

export const setBoatType = (boatType) => ({
  type: Types.SET_BOATTYPE,
  payload: {
    boatType,
  },
});

export const setMotorType = (motorType) => ({
  type: Types.SET_MOTORTYPE,
  payload: {
    motorType,
  },
});

export const setBoatWeight = (boatWeight) => ({
  type: Types.SET_BOATWEIGHT,
  payload: {
    boatWeight,
  },
});

export const removeMotor = (price) => ({
  type: Types.REMOVE_MOTOR,
  payload: {
    price,
  },
});

export const removeBattery = (price) => ({
  type: Types.REMOVE_BATTERY,
  payload: {
    price,
  },
});

export const removeCharger = (price) => ({
  type: Types.REMOVE_CHARGER,
  payload: {
    price,
  },
});

export const removeAccessory = (price) => ({
  type: Types.REMOVE_ACCESSORY,
  payload: {
    price,
  },
});

export const flushCart = () => ({
  type: Types.FLUSH_CART,
  payload: {},
});

export const Actions = {
  addAccessory,
  addBattery,
  addMotor,
  addCharger,
  setBoatType,
  setMotorType,
  setBoatWeight,
  removeMotor,
  removeBattery,
  removeCharger,
  removeAccessory,
  flushCart,
};

export default cart;
