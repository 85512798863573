import ApiHandlerService from '../../services/api-handler';
import appConfig from '../../config';

export default class UserdataApi {
  static sendUserdata(variables) {
    const url = appConfig.SEND_USER_DATA_ENDPOINT;

    const config = {
      method: 'POST',
      body: variables.userdata,
    };

    // Use _GET_ https://jsonplaceholder.typicode.com/posts as URL for testing
    return ApiHandlerService.fetch(url, config);
  }
}
