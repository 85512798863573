import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import { reactRef } from '../../../models/react';

export default class BoatInfoView extends Component {
  static propTypes = {
    sailingCheckbox: reactRef,
    motorboatCheckbox: reactRef,
    builtInCheckBox: reactRef,
    onDeckCheckbox: reactRef,
    weightInput: reactRef,
    handleCheckboxChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    onNextClick: PropTypes.func,
    isBoatTypeInvalid: PropTypes.bool,
    isMotorTypeInvalid: PropTypes.bool,
    isWeightInvalid: PropTypes.bool,
    boatType: PropTypes.string,
    motorType: PropTypes.string,
    boatWeight: PropTypes.string,
  };

  render() {
    const {
      sailingCheckbox,
      motorboatCheckbox,
      builtInCheckBox,
      onDeckCheckbox,
      weightInput,
      handleCheckboxChange,
      handleInputChange,
      onNextClick,
      isBoatTypeInvalid,
      isMotorTypeInvalid,
      isWeightInvalid,
      boatType,
      motorType,
      boatWeight,
    } = this.props;

    return (
      <>
        <h3 className="mb-4 mx-15">1. {i18n.t('priceRequest.info.title')}</h3>
        <Col xs={12} md={9} lg={7} xl={6}>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  <strong>
                    {i18n.t('priceRequest.info.boatCheckbox.label')}
                  </strong>
                </Form.Label>
                <Form.Check
                  custom
                  type="radio"
                  label={i18n.t('priceRequest.info.boatCheckbox.sailingLabel')}
                  checked={boatType === 'sailing'}
                  onChange={handleCheckboxChange}
                  isInvalid={isBoatTypeInvalid}
                  name="boatTypeCheckbox"
                  id="sailingCheckbox"
                  ref={sailingCheckbox}
                />
                <Form.Check
                  custom
                  type="radio"
                  label={i18n.t(
                    'priceRequest.info.boatCheckbox.motorboatLabel'
                  )}
                  checked={boatType === 'motorboat'}
                  onChange={handleCheckboxChange}
                  isInvalid={isBoatTypeInvalid}
                  name="boatTypeCheckbox"
                  id="motorboatCheckbox"
                  ref={motorboatCheckbox}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  <strong>
                    {i18n.t('priceRequest.info.motorCheckbox.label')}
                  </strong>
                </Form.Label>
                <Form.Check
                  custom
                  type="radio"
                  label={i18n.t('priceRequest.info.motorCheckbox.builtinLabel')}
                  checked={motorType === 'inner'}
                  onChange={handleCheckboxChange}
                  isInvalid={isMotorTypeInvalid}
                  name="motorTypeCheckbox"
                  id="builtInCheckbox"
                  ref={builtInCheckBox}
                />
                <Form.Check
                  custom
                  type="radio"
                  label={i18n.t('priceRequest.info.motorCheckbox.ondeckLabel')}
                  checked={motorType === 'outer'}
                  onChange={handleCheckboxChange}
                  isInvalid={isMotorTypeInvalid}
                  name="motorTypeCheckbox"
                  id="onDeckCheckbox"
                  ref={onDeckCheckbox}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group as={Row}>
                <Col xs={12}>
                  <Form.Label>
                    <strong>{i18n.t('priceRequest.info.weight.label')}</strong>
                  </Form.Label>
                </Col>
                <Col xs={4} sm={5} md={8}>
                  <Form.Control
                    type="text"
                    id="weightInput"
                    value={boatWeight * 1000}
                    onChange={handleInputChange}
                    isInvalid={isWeightInvalid}
                    ref={weightInput}
                  />
                </Col>
                <Form.Label column xs={3} className="py-3">
                  kg
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={3} className="ml-auto">
          <Button variant="primary w-100" onClick={onNextClick}>
            {i18n.t('priceRequest.buttons.next')}
          </Button>
        </Col>
      </>
    );
  }
}
