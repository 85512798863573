import Prismic from 'prismic-javascript';

import config from '../../config';

export default class cmsService {
  static async getData(showNotification, lang) {
    const apiEndpoint = config.PRISMIC_ENDPOINT;
    const accessToken = config.PRISMIC_ACCESS_TOKEN;
    const client = Prismic.client(apiEndpoint, { accessToken });

    const response = await client.query(
      Prismic.Predicates.at('document.type', 'page'),
      { lang } // Either 'en-gb' or 'hu' - en-gb is default
    );

    if (response) {
      const doc = response.results[0];

      return doc && doc.data;
    }

    // on error
    showNotification({
      type: 'error',
    });

    return null;
  }
}
