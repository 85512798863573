import i18n from 'i18n-js';

import hu from './locales/hu.json';
import en from './locales/en.json';

i18n.defaultLocale = 'hu';
i18n.locale = 'hu';
i18n.fallbacks = true;
i18n.translations = { hu, en };
i18n.missingBehaviour = 'guess';

export default i18n;
