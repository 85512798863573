import { connect } from 'react-redux';

import SubscribeModal from './subscribe-modal';

import { subscribe } from '../../reducers/request';

const mapStateToProps = (state) => {
  return {
    isThankYouModalOpen:
      state && state.subscribe && state.subscribe.isModalOpen,
  };
};

export default connect(mapStateToProps, {
  subscribe,
})(SubscribeModal);
