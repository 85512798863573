import { REQUEST } from '../request';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

export const Types = {
  CLOSE_MODAL,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILED,
};

export const initialState = {
  isFetching: false,
  isModalOpen: false,
};

const subscribe = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        isFetching:
          (action.payload && /SUBSCRIBE/.test(action.payload.action)) ||
          state.isFetching,
        isModalOpen: false,
      };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isModalOpen: true,
      };
    case SUBSCRIBE_FAILED:
      return {
        ...state,
        isFetching: false,
        isModalOpen: false,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };

    default:
      return state;
  }
};

export const closeThankYouModal = () => ({
  type: Types.CLOSE_MODAL,
  payload: {},
});

export const Actions = {
  closeThankYouModal,
};

export default subscribe;
