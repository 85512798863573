import ApiHandlerService from '../../services/api-handler';
import i18n from '../../i18n';

export default class ProductsApi {
  static list(variables, token) {
    const username = variables && variables.username;
    const limit = variables && variables.limit;

    // concat query parameters
    const params = {};
    if (username) params.user_name = username;
    if (limit) params.limit = limit;

    const lang = i18n.locale === 'hu' ? 'hu' : 'en';

    const url = `/data/products-${lang}.json`;

    const config = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
      },
      method: 'GET',
    };

    return ApiHandlerService.fetch(url, config);
  }
}
