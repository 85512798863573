const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const Types = {
  CHANGE_LANGUAGE,
};

export const initialState = {
  lang: 'hu',
};

const app = (state = initialState, action) => {
  if (!action) return state;
  const { type, payload } = action;

  switch (type) {
    case Types.CHANGE_LANGUAGE:
      return {
        ...state,
        lang: payload && payload.lang,
      };
    default:
      return state;
  }
};

export const changeLanguage = (lang) => ({
  type: Types.CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});

export const Actions = {
  changeLanguage,
};

export default app;
