import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import i18n from '../../../../i18n';
import ChargerDetails from '../../product-modals/charger-details';
import { chargers as chargersModel } from '../../../../models/products';

export default function ChargerTable(props) {
  const {
    chargers,
    selectedCharger,
    addCharger,
    removeCharger,
    onSelectClick,
  } = props;

  return (
    <TableContainer>
      <Table className="my-3">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className="p-2">
              {i18n.t('priceRequest.charger.engine')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.charger.voltage')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.charger.charge_current')}
            </TableCell>
            <TableCell className="p-2">
              {i18n.t('priceRequest.charger.price')}
            </TableCell>
            <TableCell className="buttonCol" />
          </TableRow>
        </TableHead>
        <TableBody>
          {chargers &&
            chargers.map((item) => (
              <ChargerDetails
                key={item && item.id}
                data={{ ...item }}
                selectedCharger={selectedCharger}
                addCharger={addCharger}
                removeCharger={removeCharger}
                onSelectClick={onSelectClick}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ChargerTable.propTypes = {
  chargers: chargersModel,
  selectedCharger: PropTypes.objectOf(PropTypes.any),
  addCharger: PropTypes.func,
  removeCharger: PropTypes.func,
  onSelectClick: PropTypes.func,
};
