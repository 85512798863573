import { connect } from 'react-redux';

import LanguageChange from './language-change';
import { changeLanguage } from '../../reducers/app';

const mapStateToProps = (state) => {
  const { lang } = state.app;
  return { lang };
};

export default connect(mapStateToProps, { changeLanguage })(LanguageChange);
